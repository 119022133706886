import { Injectable } from '@angular/core';
import { BehaviorSubject, of, map, debounceTime, Subject } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn: boolean = !this.isTokenExpired();
  refreshToken: Subject<void> = new Subject();
  constructor(private httpService: HttpService) {
    this.refreshToken.pipe(debounceTime(250)).subscribe(() => {
      this.httpService.getNewToken().subscribe((result: any) => {
        this.setToken(result.accessToken);
      });
    });
  }

  setToken(token: string) {
    localStorage.setItem('accessToken', token);
    this.loggedIn=true;
  }

  getToken() {
    return localStorage.getItem('accessToken');
  }

  checkTokenExists() {
    const token = this.getToken();
    if (token) {
      return true;
    }
    return false;
  }
  isTokenExpired() {
    let token = this.getToken();
    if (!token) {
      return true;
    }
    const jwtToken = JSON.parse(atob(token.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now();
    if (timeout <= 0) {
      return true;
    }
    return false;
  }
  login(email: string, password: string) {
    return this.httpService.login(email, password).pipe(
      map((res) => {
        this.setToken(res.accessToken);
        return res;
      })
    );
  }
  logout() {
    console.log("logout");
    localStorage.clear();
    this.loggedIn=false;
  }

  signup(name: string, email: string, password: string) {
    return this.httpService.signup(name,email,password);
  }
}
