<div class="login-body">
  <h1 class="page-heading">Login</h1>
  <div class="login-container">
    <form [formGroup]="loginForm" novalidate>
      <mat-form-field>
        <input
          autofocus
          type="text"
          matInput
          formControlName="email"
          placeholder="Email address"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          type="password"
          matInput
          formControlName="password"
          placeholder="Password"
        />
      </mat-form-field>
      <button
        type="submit"
        class="main-btn full-btn"
        mat-raised-button
        [disabled]="loginForm.invalid"
        (click)="login()"
      >
        Login
      </button>
    </form>
  </div>
</div>
