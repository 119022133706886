<div class="app-table-container shadow-lg">
  <table class="app-table" matSortDisableClear mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">
    @for(header of headers;track header){
    <ng-container [matColumnDef]="header.key">
      @if(header.key=='action'){
        <th  mat-header-cell *matHeaderCellDef [width]="header.width|| ''">
          {{ header.name }}
        </th>
        } @else{
          <th mat-sort-header mat-header-cell *matHeaderCellDef [width]="header.width|| ''">
            {{ header.name }}
          </th>
        }
      
      <td mat-cell *matCellDef="let element">
        @if(header.key=='action'){
        <div class="flex">
          
          <button class="edit-btn" (click)="onEdit(element)">Edit</button>
          @if(isDelete){
            <button class="delete-btn" (click)="onDelete(element)">Delete</button>
          }
          <button *ngIf="!element.published" class="publish-btn" (click)="onPublish(element)">Publish</button>
        </div>
        } @else{
        {{ header.resolve ? header.resolve(element) : element[header.key] }}
        }
      </td>
    </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cel" [attr.colspan]="displayedColumns.length">No records.</td>
    </tr>
  </table>
</div>
<mat-paginator [length]="total" [pageIndex]="filter.page" [pageSize]="10" [pageSizeOptions]="[10, 20]" showFirstLastButtons (page)="pageChange($event)">
</mat-paginator>
