import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSelectSearchModule } from 'mat-select-search';
import { ColorPickerModule } from 'ngx-color-picker';
import { combineLatest, map } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { ControlNameType, ControlState, IDynamicDataControl, PageType, TopicsType } from '../../constants/enums';
import { MaterailModule } from '../../materail/materail.module';
import { CacheDataService } from '../../services/cache-data.service';
import { HttpService } from '../../services/http.service';
import { LoaderService } from '../../services/loader.service';
import { NotificationService } from '../../services/notification.service';
import { ICompany, IDistributor, IPageType, ITemplate } from '../../types/auth-response';
import { FileUploadComponent } from '../shared/file-upload/file-upload.component';
import { LoadingPlaceholderComponent } from '../shared/loading-placeholder/loading-placeholder.component';
import { ScriptsInputComponent } from '../shared/scripts-input/scripts-input.component';
import { SelectBrowserComponent } from '../shared/select-browser/select-browser.component';
import { TemplatePreviewComponent } from '../shared/template-preview/template-preview.component';
import { SearchDomainPage } from './search-domain-page';

@Component({
  selector: 'app-search-domain-page-form',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectSearchModule,
    SelectBrowserComponent,
    FileUploadComponent,
    ScriptsInputComponent,
    ColorPickerModule,
    LoadingPlaceholderComponent,
    TemplatePreviewComponent,
    MaterailModule,
  ],
  standalone: true,
  template: `<div class="page-header">
      <h2>Search Domain Page</h2>
    </div>
    <form [formGroup]="pageForm">
      <div class="data-card" [ngClass]="{ 'opacity-40': !editMode }">
        <div class="flex w-full">
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Type of Static Page <span class="required">*</span></mat-label>
              <mat-form-field>
                <mat-select formControlName="pageTypeId">
                  @for (item of pageTypes; track $index) {
                  <mat-option [value]="item.pageTypeId">
                    {{ item.pageTypeName }}
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="w-4/12">
            @if(templatesLoading){
            <app-loading-placeholder></app-loading-placeholder>
            } @else if(pageForm.controls.pageTypeId.value){

            <div class="form-field">
              <mat-label>Select Template <span class="required">*</span></mat-label>
              <mat-form-field subscriptSizing="dynamic">
                <mat-select formControlName="templateId">
                  @for (item of templateTypes; track $index) {
                  <mat-option [value]="item.templateId">
                    {{ item.templateName }}
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            @if(pageForm.controls.templateId.value){
            <span class="small-link mt-2" (click)="previewPage()">See Preview</span>
            } }
          </div>
        </div>
      </div>
      @if(pageForm.controls.pageTypeId.value){
      <div class="data-card" [ngClass]="{ 'opacity-40': !editMode }">
        <div class="flex w-full flex-wrap">
          @if(showControl('domainName')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Domain <span class="required">*</span></mat-label>
              <mat-form-field><input type="text" matInput formControlName="domainName" /></mat-form-field>
            </div>
          </div>
          } @if(showControl('productName')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Product Name <span class="required">*</span></mat-label>
              <mat-form-field><input type="text" matInput formControlName="productName" /></mat-form-field>
            </div>
          </div>
          } @if(showControl('logo')){
          <div class="w-4/12">
            <app-file-upload
              [label]="'Logo image'"
              [imageUrl]="pageForm.controls.logo.value!"
              (imageUpload)="pageForm.controls.logo.setValue($event)"
              [disabled]="pageForm.controls.logo.disabled"
            ></app-file-upload>
          </div>
          } @if(showControl('supportEmail')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Support Email <span class="required">*</span></mat-label>
              <mat-form-field><input type="text" matInput formControlName="supportEmail" /></mat-form-field>
            </div>
          </div>
          } @if(showControl('icon')){
          <div class="w-4/12">
            <app-file-upload
              [label]="'Icon image'"
              [imageUrl]="pageForm.controls.icon.value!"
              (imageUpload)="pageForm.controls.icon.setValue($event)"
              [disabled]="pageForm.controls.icon.disabled"
            ></app-file-upload>
          </div>
          } @if(showControl('headlineBackgroundColor')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Headline - Bg Color <span class="required">*</span></mat-label>
              <mat-form-field>
                <input
                  matInput
                  [readonly]="true"
                  [cpOKButton]="true"
                  [colorPicker]="pageForm.controls.headlineBackgroundColor.value!"
                  (colorPickerSelect)="pageForm.controls.headlineBackgroundColor.setValue($event)"
                  [style.background]="pageForm.controls.headlineBackgroundColor.value"
                  [cpDisabled]="pageForm.controls.headlineBackgroundColor.disabled"
                />
              </mat-form-field>
            </div>
          </div>
          } @if(showControl('footerBackgroundColor')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Footer - Bg Color <span class="required">*</span></mat-label>
              <mat-form-field>
                <input
                  matInput
                  [readonly]="true"
                  [cpOKButton]="true"
                  [colorPicker]="pageForm.controls.footerBackgroundColor.value!"
                  (colorPickerSelect)="pageForm.controls.footerBackgroundColor.setValue($event)"
                  [style.background]="pageForm.controls.footerBackgroundColor.value"
                  [cpDisabled]="pageForm.controls.footerBackgroundColor.disabled"
                />
              </mat-form-field>
            </div>
          </div>
          } @if(showControl('browserId')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Select Browser <span class="required">*</span></mat-label>
              <app-select-browser
                [multiple]="false"
                [value]="pageForm.controls.browserId.value!"
                (changed)="pageForm.controls.browserId.setValue($event)"
                [disabled]="pageForm.controls.browserId.disabled"
              ></app-select-browser>
            </div>
          </div>
          } @if(showControl('browserName')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Browser Name <span class="required">*</span></mat-label>
              <mat-form-field><input type="text" matInput formControlName="browserName" /></mat-form-field>
            </div>
          </div>
          } @if(showControl('section1BackgroundColor')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Section 1 - Bg Color <span class="required">*</span></mat-label>
              <mat-form-field>
                <input
                  matInput
                  [readonly]="true"
                  [cpOKButton]="true"
                  [colorPicker]="pageForm.controls.section1BackgroundColor.value!"
                  (colorPickerSelect)="pageForm.controls.section1BackgroundColor.setValue($event)"
                  [style.background]="pageForm.controls.section1BackgroundColor.value"
                  [cpDisabled]="pageForm.controls.section1BackgroundColor.disabled"
                />
              </mat-form-field>
            </div>
          </div>
          } @if(showControl('section1Image')){
          <div class="w-4/12">
            <app-file-upload
              [label]="'Section 1 - Image'"
              [imageUrl]="pageForm.controls.section1Image.value!"
              (imageUpload)="pageForm.controls.section1Image.setValue($event)"
              [disabled]="pageForm.controls.section1Image.disabled"
            ></app-file-upload>
          </div>
          } @if(showControl('section1Headline')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Section 1 Heading <span class="required">*</span></mat-label>
              <mat-form-field><input type="text" matInput formControlName="section1Headline" /></mat-form-field>
            </div>
          </div>
          } @if(showControl('section1Text')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Section 1 Text <span class="required">*</span></mat-label>
              <mat-form-field><input type="text" matInput formControlName="section1Text" /></mat-form-field>
            </div>
          </div>
          } @if(showControl('section2BackgroundColor')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Section 2 - Bg Color <span class="required">*</span></mat-label>
              <mat-form-field>
                <input
                  matInput
                  [readonly]="true"
                  [cpOKButton]="true"
                  [colorPicker]="pageForm.controls.section2BackgroundColor.value!"
                  (colorPickerSelect)="pageForm.controls.section2BackgroundColor.setValue($event)"
                  [style.background]="pageForm.controls.section2BackgroundColor.value"
                  [cpDisabled]="pageForm.controls.section2BackgroundColor.disabled"
                />
              </mat-form-field>
            </div>
          </div>
          } @if(showControl('section2Image')){
          <div class="w-4/12">
            <app-file-upload
              [label]="'Section 2 - Image'"
              [imageUrl]="pageForm.controls.section2Image.value!"
              (imageUpload)="pageForm.controls.section2Image.setValue($event)"
              [disabled]="pageForm.controls.section2Image.disabled"
            ></app-file-upload>
          </div>
          } @if(showControl('section2Headline')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Section 2 - Heading <span class="required">*</span></mat-label>
              <mat-form-field><input type="text" matInput formControlName="section2Headline" /></mat-form-field>
            </div>
          </div>
          } @if(showControl('section2Text')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Section 2 - Text <span class="required">*</span></mat-label>
              <mat-form-field><input type="text" matInput formControlName="section2Text" /></mat-form-field>
            </div>
          </div>
          } @if(showControl('section3BackgroundColor')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Section 3 - Bg Color <span class="required">*</span></mat-label>
              <mat-form-field>
                <input
                  matInput
                  [readonly]="true"
                  [cpOKButton]="true"
                  [colorPicker]="pageForm.controls.section3BackgroundColor.value!"
                  (colorPickerSelect)="pageForm.controls.section3BackgroundColor.setValue($event)"
                  [style.background]="pageForm.controls.section3BackgroundColor.value"
                  [cpDisabled]="pageForm.controls.section3BackgroundColor.disabled"
                />
              </mat-form-field>
            </div>
          </div>
          } @if(showControl('useOxml')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Use OXml</mat-label>
              <mat-checkbox class="check-box" color="primary" formControlName="useOxml"></mat-checkbox>
            </div>
          </div>
          }
        </div>
      </div>
      }
      @if(!pageId){
      <div class="flex w-full justify-center">
        <button mat-raised-button class="main-btn w-48" (click)="add()">Save</button>
      </div>
      }@else{
      <div class="flex w-full justify-center gap-8">
        @if(editMode){
        <button mat-raised-button class="main-btn outlined-btn w-48" (click)="update()">Update</button>
        }@else {
        <button mat-raised-button class="main-btn outlined-btn w-48" (click)="edit()">Edit</button>
        <button mat-stroked-button class="main-btn w-48" (click)="publish()">Publish</button>
        }
      </div>
      }
    </form> `,
  styles: `
  .check-box{
    ::ng-deep{

    .mdc-checkbox{
      transform: scale(2) translate(13px, 4px) !important;
    }
    }
  }
  `,
})
export class SearchDomainPageFormComponent {
  color = 'red';
  router = inject(Router);
  route = inject(ActivatedRoute);
  httpService = inject(HttpService);
  cacheDataService = inject(CacheDataService);
  formBuilder = inject(FormBuilder);
  loader = inject(LoaderService);
  dialog = inject(MatDialog);
  overlay = inject(Overlay);
  notificationService = inject(NotificationService);
  pageId: string | null = null;
  pageTypes: IPageType[] = [];
  PageTypeEnum = PageType;
  templateTypes: ITemplate[] = [];
  distributors: IDistributor[] = [];
  companies: ICompany[] = [];
  templatesLoading = false;
  editMode = false;
  isScriptOpen = false;
  pageForm = this.formBuilder.group({
    pageTypeId: [0],
    templateId: [0],
    browserId: [0],
    browserName: [''],
    domainName: [''],
    supportEmail: [''],
    icon: [''],
    logo: [''],
    productName: [''],
    headlineBackgroundColor: [''],
    footerBackgroundColor: [''],
    section1BackgroundColor: [''],
    section1Image: [''],
    section1Headline: [''],
    section1Text: [''],
    section2BackgroundColor: [''],
    section2Image: [''],
    section2Headline: [''],
    section2Text: [''],
    section3BackgroundColor: [''],
    useOxml: [true],
  });
  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(map((results) => ({ params: results[0], query: results[1] })))
      .subscribe((results) => {
        this.pageId = results.params['id'] || null;
        const editmode = results.query['editmode'] || 'true';
        console.log('nginit', this.pageId, editmode);
        if (this.pageId) {
          this.initEdit(this.pageId, editmode == 'true');
        } else {
          this.initAdd();
        }
      });
  }

  initAdd() {
    this.editMode = true;
    this.loader.show();
    this.httpService.getPageTypeByTopic(this.cacheDataService.topicHaskey[TopicsType.SearchDomainPages]).subscribe((result) => {
      this.pageTypes = result.data;
      this.loader.hide();
    });
    this.setControlState(true);
    this.handleValueChanges();
  }
  initEdit(id: string, editMode: boolean) {
    this.loader.show();
    const pageTypes = this.httpService.getPageTypeByTopic(this.cacheDataService.topicHaskey[TopicsType.SearchDomainPages]);
    const pageDetail = this.httpService.getSearchDomainPage(+id);
    forkJoin([pageTypes, pageDetail]).subscribe({
      next: (results) => {
        this.pageTypes = results[0].data;
        const formValues = results[1];
        this.pageForm.patchValue(formValues);
        this.pageForm.controls.pageTypeId.disable();
        this.loader.hide();
        this.editMode = editMode;
        this.bindTemplateType(this.pageForm.controls.pageTypeId.value || 0);
        this.setControlState(false);
        setTimeout(() => {
          this.handleValueChanges();
        });

        if (!editMode) {
          setTimeout(() => {
            this.pageForm.disable({ emitEvent: false });
          }, 1000);
        }
      },
      complete: () => {
        this.loader.hide();
      },
    });
  }
  get pageTypeHaskey() {
    let hash: any = {};
    this.pageTypes.forEach((e) => {
      hash[e.pageTypeId] = e.pageTypeName.toLowerCase().trim();
    });
    return hash;
  }
  showControl(controlName: string) {
    const searchDomainPage = new SearchDomainPage(this.pageForm.getRawValue(),this.controlsData);
    return !searchDomainPage.isHidden(controlName);
  }
  get pageTypeName() {
    return this.pageTypeHaskey[this.pageForm.value.pageTypeId!] as PageType;
  }
  add() {
    let formValue = this.pageForm.getRawValue();
    const searchDomainPage = new SearchDomainPage(formValue,this.controlsData);
    const result = searchDomainPage.isFormValid();
    if (!result.isValid) {
      this.notificationService.showErros(result.errors);
      return;
    }
    searchDomainPage.replaceBeforeResolver(this.controlsData);
    this.loader.show();
    this.httpService.addSearchDomainPage(searchDomainPage.removeBeforeSave()).subscribe((result) => {
      this.loader.hide();
      if (result && result.id) {
        this.notificationService.success('Page saved sucessfully');
        this.router.navigateByUrl('search-domains/' + result.id + '?editmode=false');
      }
    });
  }
  edit() {
    this.editMode = true;
    this.pageForm.enable({ emitEvent: false });
    setTimeout(() => {
      this.pageForm.controls.pageTypeId.disable({ emitEvent: false });
      this.setControlState(false);
    });
  }
  update() {
    let formValue: any = this.pageForm.getRawValue();
    const searchDomainPage = new SearchDomainPage(formValue,this.controlsData);
    const result = searchDomainPage.isFormValid();
    if (!result.isValid) {
      this.notificationService.showErros(result.errors);
      return;
    }
    searchDomainPage.removeBeforeSave();
    this.loader.show();
    this.httpService.updateSearchDomainPage(+this.pageId!,searchDomainPage.removeBeforeSave()).subscribe((result) => {
      this.loader.hide();
      this.notificationService.success('Page updated successfully');
      this.editMode = false;
      this.pageForm.disable({ emitEvent: false });
    });
  }
  publish() {
    this.loader.show();
    this.httpService.publishSearchDomainPage(+this.pageForm.value.templateId!, +this.pageId!).subscribe({
      next: (result) => {
        if (result.isPublishedSucceeded) window.open(result.publishedUrl);
        this.notificationService.success('Page published sucessfully');
        this.router.navigateByUrl('search-domains');
        this.loader.hide();
      },
    });
  }
  previewPage() {
    let template = this.templateTypes.find((x) => x.templateId == this.pageForm.controls.templateId.value!);
    let scrollStrategy = this.overlay.scrollStrategies.reposition();
    this.dialog.open(TemplatePreviewComponent, {
      width: '80vw',
      height: '80vh',
      panelClass: 'template-preview',
      scrollStrategy: scrollStrategy,
      data: {
        template: template,
      },
    });
  }
  bindTemplateType(pageTypeId?: number) {
    this.templatesLoading = true;
    this.httpService.getTemplateByPagetype(pageTypeId).subscribe({
      next: (result: any) => {
        this.templateTypes = result.data;
        this.templatesLoading = false;
      },
    });
  }
  handleValueChanges() {
    console.log('handleControlStateAndValue');
    
    this.pageForm.controls.pageTypeId.valueChanges.subscribe((value) => {
      console.log('pageTypeId', value);
      this.pageForm.patchValue({
        templateId: 0,
      });
      this.bindTemplateType(value!);
      this.setControlState(true);
    });
    this.pageForm.controls.supportEmail.valueChanges.subscribe((value) => {
      this.controlsData
        .filter((x) => x.autoFillFrom == ControlNameType.SupportMail)
        .forEach((control) => {
          let page = new SearchDomainPage(this.pageForm.getRawValue(),this.controlsData);
          this.pageForm.get(control.name)?.setValue(page.autoFillResolver(control, this.cacheDataService));
        });
    });
    this.pageForm.controls.browserId.valueChanges.subscribe((value) => {
      console.log("browserId",value)
      this.controlsData
        .filter((x) => x.autoFillFrom == ControlNameType.BrowserName)
        .forEach((control) => {
          let page = new SearchDomainPage(this.pageForm.getRawValue(),this.controlsData);
          this.pageForm.get(control.name)?.setValue(page.autoFillResolver(control, this.cacheDataService));
        });
    });
    this.pageForm.controls.domainName.valueChanges.subscribe((value) => {
      this.controlsData
        .filter((x) => x.autoFillFrom == ControlNameType.DomainName)
        .forEach((control) => {
          let page = new SearchDomainPage(this.pageForm.getRawValue(),this.controlsData);
          this.pageForm.get(control.name)?.setValue(page.autoFillResolver(control, this.cacheDataService));
        });
    });
  }
  get controlsData() {
    let controls: IDynamicDataControl[] = [];
    let pageTypeKey: string = this.pageTypeHaskey[this.pageForm.controls.pageTypeId.value!];
    if (!pageTypeKey) return controls;
    let pageTypeData = this.cacheDataService.searchDomainPageControlConfig.find((x) => x.pageTypeId == pageTypeKey);
    controls = pageTypeData?.controls || [];
    return controls;
  }
  private setControlState(updateValue: boolean) {
    console.log('this.controlsData', this.controlsData);
    let page = new SearchDomainPage(this.pageForm.getRawValue(),this.controlsData);
    this.controlsData?.forEach((element) => {
      let formControl = this.pageForm.get(element.name);
      if (updateValue) {
        let value = page.autoFillResolver(element, this.cacheDataService);
        console.log("here 1",element,value)
        if(value){
          console.log("here 1",element,value)
        }
        formControl?.setValue(value);
      }
      switch (element.state) {
        case ControlState.Hidden:
          formControl?.disable();
          break;
        case ControlState.Enable:
          formControl?.enable();
          break;
        case ControlState.Disable:
          formControl?.disable();
          break;
      }
    });
  }
}
