import { ControlNameType, ControlState, IDynamicDataControl, replaceBeforeSaveList } from '../../constants/enums';
import { CacheDataService } from '../../services/cache-data.service';
import { ISearchDomainPage } from '../../types/search-domain-page';


export class SearchDomainPage implements ISearchDomainPage {
  constructor(init: any, private controls: IDynamicDataControl[]) {
    Object.assign(this, init);
  }
  searchDomainStaticPageId?: number | undefined;
  published!: boolean;
  pageTypeId!: number;
  templateId!: number;
  browserId!: number;
  browserName!: string;
  domainName!: string;
  supportEmail!: string;
  icon!: string;
  logo!: string;
  productName!: string;
  headlineBackgroundColor!: string;
  footerBackgroundColor!: string;
  section1BackgroundColor!: string;
  section1Image!: string;
  section1Headline!: string;
  section1Text!: string;
  section2BackgroundColor!: string;
  section2Image!: string;
  section2Headline!: string;
  section2Text!: string;
  section3BackgroundColor!: string;
  useOxml!: boolean;

  getControlState(controlName: string) {
    const control = this.controls.find((x) => x.name == controlName);
    if (control) {
      return control.state || ControlState.Enable;
    }
    return ControlState.Hidden;
  }
  isFormValid() {
    let isValid = true;
    let errors: string[] = [];

    if (!this.pageTypeId) {
      isValid = false;
      errors.push('Select page type');
    }
    if (!this.templateId) {
      isValid = false;
      errors.push('Select template');
    }
    if (!this.browserId && this.isEnabled('browserId')) {
      isValid = false;
      errors.push('Select browser');
    }

    if (!this.browserName && this.isEnabled('browserName')) {
      isValid = false;
      errors.push('Enter browser name');
    }
    if (!this.domainName && this.isEnabled('domainName')) {
      isValid = false;
      errors.push('Enter domain name');
    }

    if (!this.supportEmail && this.isEnabled('supportEmail')) {
      isValid = false;
      errors.push('Enter support email');
    }

    if (!this.icon && this.isEnabled('icon')) {
      isValid = false;
      errors.push('Upload icon');
    }
    if (!this.logo && this.isEnabled('logo')) {
      isValid = false;
      errors.push('Upload logo');
    }
    if (!this.productName && this.isEnabled('productName')) {
      isValid = false;
      errors.push('Enter product name');
    }

    if (!this.headlineBackgroundColor && this.isEnabled('headlineBackgroundColor')) {
      isValid = false;
      errors.push('Enter headline BackgroundColor');
    }
    if (!this.footerBackgroundColor && this.isEnabled('footerBackgroundColor')) {
      isValid = false;
      errors.push('Select footer BackgroundColor');
    }
    if (!this.section1BackgroundColor && this.isEnabled('section1BackgroundColor')) {
      isValid = false;
      errors.push('Select section1 BackgroundColor');
    }
    if (!this.section1Image && this.isEnabled('section1Image')) {
      isValid = false;
      errors.push('Upload section1 Image');
    }
    if (!this.section1Headline && this.isEnabled('section1Headline')) {
      isValid = false;
      errors.push('Enter section1 Headline');
    }
    if (!this.section1Text && this.isEnabled('section1Text')) {
      isValid = false;
      errors.push('Enter section1 Text');
    }
    if (!this.section2BackgroundColor && this.isEnabled('section2BackgroundColor')) {
      isValid = false;
      errors.push('Select section2 BackgroundColor');
    }
    if (!this.section2Image && this.isEnabled('section2Image')) {
      isValid = false;
      errors.push('Upload section2 Image');
    }
    if (!this.section2Headline && this.isEnabled('section2Headline')) {
      isValid = false;
      errors.push('Enter section2 Headline');
    }
    if (!this.section2Text && this.isEnabled('section2Text')) {
      isValid = false;
      errors.push('Enter section2 Text');
    }
    if (!this.section3BackgroundColor && this.isEnabled('section3BackgroundColor')) {
      isValid = false;
      errors.push('Select section3 BackgroundColor');
    }
    console.log(isValid);
    return { isValid, errors };
  }
  isEnabled(controlName: string) {
    return this.getControlState(controlName) == ControlState.Enable;
  }
  isHidden(controlName: string) {
    return this.getControlState(controlName) == ControlState.Hidden;
  }
  isDisabled(controlName: string) {
    return this.getControlState(controlName) == ControlState.Disable;
  }
  autoFillResolver = (controlData: IDynamicDataControl, cacheDataService: CacheDataService) => {
    let page = this;
    switch (controlData.autoFillFrom) {
      case ControlNameType.SupportMail:
        return page.supportEmail || '';
      case ControlNameType.Domain:
        return page.domainName || '';
      case ControlNameType.DomainName:
        return page.domainName || '';
      case ControlNameType.SupportMail:
        return page.supportEmail || '';
      case ControlNameType.BrowserName:
        return cacheDataService.brosersList?.find((x) => page.browserId == x.browserId)?.browserName;
      default:
        return controlData.value != null && controlData.value != undefined ? controlData.value : '';
    }
  };
  replaceBeforeResolver = (controls: IDynamicDataControl[]) => {
    let page = this;
    controls?.forEach((element) => {
      replaceBeforeSaveList.forEach((control) => {
        if (element.isReplaceBeforeSave) {
          switch (control.to) {
            case ControlNameType.Domain:
              (page as any)[element.name] = (page as any)[element.name].replace(control.from, page.domainName);
              break;
          }
        }
      });
    });
  };
  removeBeforeSave() {
    let page = { ...this };
    for (let key in page) {
      if (['pageTypeId', 'templateId', 'browserIds', 'distributerId', 'companyId', 'pageName', 'icon', 'scripts'].includes(key)) continue;
      if (this.isHidden(key)) {
        console.log(key);
        console.log(key, typeof page[key]);
        delete page[key];
        // if (typeof formValue[key] == 'string') {
        //   formValue[key] = '';
        // } else if (typeof formValue[key] == 'number') {
        //   formValue[key] = 0;
        // } else if (typeof formValue[key] == 'boolean') {
        //   formValue[key] = false;
        // } else {
        //   formValue[key] = null;
        // }
      }
    }
    return page;
  }
}
