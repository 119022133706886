import { Routes } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { AuthGuard } from './guards/auth-guard';
import { ExtensionStaticPagesComponent } from './components/extension-static-pages/extension-static-pages.component';
import { StaticPageFormComponent } from './components/extension-static-pages/static-page-form/static-page-form.component';
import { ExtensionLandingPagesComponent } from './components/extension-landing-pages/extension-landing-pages.component';
import { LandingPageFormComponent } from './components/extension-landing-pages/landing-page-form/landing-page-form.component';
import { SearchDomainPagesComponent } from './components/search-domain-pages/search-domain-pages.component';
import { InstallerStaticPagesComponent } from './components/installer-static-pages/installer-static-pages.component';
import { SearchDomainPageFormComponent } from './components/search-domain-pages/search-domain-page-form.component';

export const routes: Routes = [
    {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full',
    },
    {
      path: '',
      component: LayoutComponent,
      children: [
        {
          path: 'login',
          component: LoginComponent,
        },
        {
          path: 'signup',
          component: SignUpComponent,
          canActivate: [AuthGuard],
        },
        {
          path: 'extension-statics',
          canActivate: [AuthGuard],
          children: [
            {
              path: '',
              component: ExtensionStaticPagesComponent
            },
            {
              path: 'add',
              component: StaticPageFormComponent,
            },
            {
              path: ':id',
              component: StaticPageFormComponent,
            },
          ],
        },
        {
          path: 'extension-landings',
          canActivate: [AuthGuard],
          children:[
            {
              path:'',
              component:ExtensionLandingPagesComponent
            },
            {
              path: 'add',
              component: LandingPageFormComponent,
            },
            {
              path: ':id',
              component: LandingPageFormComponent,
            }
          ]
        },
        {
          path: 'search-domains',
          canActivate: [AuthGuard],
          children:[
            {
              path:'',
              component:SearchDomainPagesComponent
            },
            {
              path: 'add',
              component: SearchDomainPageFormComponent,
            },
            {
              path: ':id',
              component: SearchDomainPageFormComponent,
            }
          ]
        },
        {
          path: 'installer-statics',
          component: InstallerStaticPagesComponent,
          canActivate: [AuthGuard],
        },
      ],
    },
    {
      path: '**',
      redirectTo: '',
      pathMatch: 'full',
    },
  ];

