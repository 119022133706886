import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmBoxComponent } from '../shared/confirm-box/confirm-box.component';
import { MaterailModule } from '../../materail/materail.module';
import { StaticPageFormComponent } from './static-page-form/static-page-form.component';
import { ScriptsInputComponent } from '../shared/scripts-input/scripts-input.component';
import { TableComponent } from '../shared/table/table.component';
import { CommonModule, DOCUMENT } from '@angular/common';
import { HttpService } from '../../services/http.service';
import { IPageType, ListResultResponse } from '../../types/auth-response';
import { IStaticPage } from '../../types/static-page';
import { LoaderService } from '../../services/loader.service';
import { MatSelectChange } from '@angular/material/select';
import { TopicsType } from '../../constants/enums';
import { NotificationService } from '../../services/notification.service';
import { CacheDataService } from '../../services/cache-data.service';
import { HelperService } from '../../services/helper.service';
import { MatSort, Sort } from '@angular/material/sort';
import { SearchFilterComponent } from '../shared/search-filter.component';
import { filter } from 'rxjs';
import { scriptTypes } from '../shared/script-types';

@Component({
  selector: 'app-extension-static-pages',
  templateUrl: './extension-static-pages.component.html',
  styles: ``,
  imports: [CommonModule, MaterailModule, StaticPageFormComponent, ScriptsInputComponent, TableComponent, SearchFilterComponent],
  standalone: true,
})
export class ExtensionStaticPagesComponent {  
  router = inject(Router);
  route = inject(ActivatedRoute);
  dialog = inject(MatDialog);
  httpService = inject(HttpService);
  loader = inject(LoaderService);  
  initialPageList!: IStaticPage[];
  filteredPageList!: IStaticPage[];
  totalPages!: number;
  scriptTypes: string[] = scriptTypes;
  headers = [
    {
      key: 'pageTypeId',
      name: 'Page Type',
      resolve: (row: any) => {
        return this.pageTypes.find((x) => x.pageTypeId == row.pageTypeId)?.pageTypeName || '';
      },
      width:'12%'
    },
    {
      key: 'pageName',
      name: 'Page Name',
      width:'15%'
    },
    {
      key: 'publishedUrl',
      name: 'Published Url',
    },
    {
      key: 'updatedAt',
      name: 'Last Updated On',
      resolve: (row: any) => {
        let updateDate = row.updatedAt;
        if (updateDate && updateDate !== '') {
          let dateFormatStringMonth = new Date(updateDate).toString();
          let dateFormatNumbers = new Date(updateDate).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          });
          let timeFormatNumbers = new Date(updateDate).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
          });
          let splitedDate = dateFormatNumbers.split('/');
          let splitedHour = timeFormatNumbers.split(':');
          let month = dateFormatStringMonth.split(' ')[1];
          let day = splitedDate[1];
          let year = splitedDate[2];
          let hour = splitedHour[0];
          let minutes = splitedHour[1];
          let stringDate = `${day} ${month} ${year} | ${hour}:${minutes}`;
          return stringDate;
        }
        return updateDate;
      },
      width:'15%'
    },
    {
      key: 'published',
      name: 'Published',
      resolve: (row: any, coloum: any) => {
        if (row.published) {
          return 'Yes';
        }
        return 'No';
      },
      width:'12%'
    },
    {
      key: 'action',
      name: 'Action',
      width:'15%'
    },
  ];
  filter = {
    page: 0,
    search: '',
    published: '',
  };
  pageTypes: IPageType[] = [];
  notificationService = inject(NotificationService);
  cacheDataService = inject(CacheDataService);
  helperService = inject(HelperService);
  ngOnInit() {
    this.fetchPages();
    console.log(this.cacheDataService.topicHaskey, 'gh', this.cacheDataService.topicHaskey[TopicsType.ExtensionsStaticPages]);
    this.httpService.getPageTypeByTopic(this.cacheDataService.topicHaskey[TopicsType.ExtensionsStaticPages]).subscribe((result) => {
      this.pageTypes = result.data;
    });
  }

  private fetchPages() {
    this.loader.show();
    this.httpService.getStaticPages().subscribe((result) => {
      this.filteredPageList = result.data;
      this.initialPageList = result.data;
      this.totalPages = result.totalMatchRecords;
      this.loader.hide();
    });
  }
  searchChange(search: string) {
    console.log(search);
    if(typeof search =="string"){
      this.filter.search = search;
      this.onFilter();
    }
  }
  publishFilter(event: MatSelectChange) {
    this.filter.published = event.value;
    this.onFilter();
  }
  onFilter() {
    console.log(this.filter);
    let filetredData: IStaticPage[] = this.initialPageList;
    if (this.filter.published) {
      filetredData = filetredData.filter(
        (x) => (+this.filter.published == 1 && x.published == true) || (+this.filter.published == 2 && x.published == false)
      );
    }
    if (this.filter.search) {
      filetredData = filetredData.filter((x) => x.publishedUrl?.toLowerCase().includes(this.filter.search?.toLowerCase()));
    }
    this.filteredPageList = filetredData;
    this.totalPages = filetredData.length;
    this.filter.page = 0;
  }
  addNewPage() {
    this.router.navigateByUrl('/extension-statics/add');
  }
  edit(row: IStaticPage) {
    this.router.navigateByUrl('/extension-statics/' + row.extensionStaticPageId);
  }
  publish(row: IStaticPage) {
    this.loader.show();
    this.httpService.publishStaticPage(+row.templateId!, +row.extensionStaticPageId!).subscribe((result) => {
      if (result.isPublishedSucceeded) window.open(result.publishedUrl);
      this.notificationService.success('Page published sucessfully');
      this.router.navigateByUrl('extension-statics');
      this.loader.hide();
      this.fetchPages();
    });
  }
  sortData(sort: Sort) {
    const data = this.filteredPageList.slice();
    if (!sort.active || sort.direction === '') {
      this.filteredPageList = data;
      return;
    }

    this.filteredPageList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'pageTypeId':
          return this.helperService.compareString(
            this.pageTypes.find((x) => x.pageTypeId == a.pageTypeId)?.pageTypeName || '',
            this.pageTypes.find((x) => x.pageTypeId == b.pageTypeId)?.pageTypeName || '',
            isAsc
          );
        case 'pageName':
          return this.helperService.compareString(a.pageName, b.pageName, isAsc);
        case 'publishedUrl':
          return this.helperService.compareString(a.publishedUrl, b.publishedUrl, isAsc);
        case 'published':
          return this.helperService.compareString(a.published ? 'Yes' : 'No', b.published ? 'Yes' : 'No', isAsc);
        case 'updatedAt':
          return this.helperService.compareDate(a.updatedAt, b.updatedAt, isAsc);
        default:
          return 0;
      }
    });
  }
}
