<header>
  <section>
    <div class="container v-center justify-between" [ngClass]="{'!justify-center':!authService.loggedIn}">
      <div class="logo">
        <a>StaticPages <span>Manager</span></a>
      </div>
      @if(authService.loggedIn){
      <div class="flex items-center justify-between">
        <div id="btnLogOut" class="w-48 flex gap-1 items-center" style="align-items: center">
          <img src="../../../../assets/icons/Icon-logout.png" style="height: 16px" />        
          <a class="green-btn ml-2 cursor-pointer" (click)="logout()">Logout</a>
          <span class="ml-4">V: {{version}}</span>
        </div>
      </div>
      }
    </div>
  </section>
  <section class="navigation" *ngIf="authService.loggedIn">
    <ul>
      <ng-container>
        <li *ngFor="let link of links" [routerLink]="[link.route]" [routerLinkActive]="'active'">
          <div>
            <span>{{ link.text }} </span>
          </div>
        </li>
      </ng-container>
    </ul>
  </section>
</header>
