import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { routes } from './app.routes';
import { httpErrorInterceptor } from './interceptors/http-error.interceptor';
import {  tokenInterceptor } from './interceptors/token-interceptor';
import { ToastrService, provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';


export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes),
  ToastrService,
  provideHttpClient(withInterceptors([tokenInterceptor,httpErrorInterceptor])),
  provideAnimations(),
  provideToastr({
    preventDuplicates:true
  })
]
};
