import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterailModule } from '../../../materail/materail.module';
import { HttpService } from '../../../services/http.service';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [CommonModule, MaterailModule],
  templateUrl: './file-upload.component.html',
  styles: ``,
})
export class FileUploadComponent {
  @Input() imageUrl = '';
  @Input() label!:string;
  @Input() disabled = false;
  @Output() imageUpload = new EventEmitter();
  httpService = inject(HttpService);

  async onImageUpload(event: any) {
    let file = event.target.files[0];
    let str = await this.getFileByteString(file);
    this.httpService.uploadImage(str).subscribe((result) => {
      console.log(result);
      this.imageUpload.emit(result.url);
    });
  }

  async getFileByteString(file: any): Promise<string> {
    return new Promise((res, rej) => {
      const fr = new FileReader();
      let fileByteArray: any = [];
      fr.onloadend = (evt: any) => {
        if (evt.target.readyState == FileReader.DONE) {
          var arrayBuffer = evt.target.result;
          let array = new Uint8Array(arrayBuffer);
          for (var i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
          let str = this.arrayBufferToBase64(fileByteArray);
          res(str);
        }
      };
      fr.readAsArrayBuffer(file);
    });
  }

  arrayBufferToBase64(buffer: any[]) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
