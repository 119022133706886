import { ControlNameType, CompanyType, ControlState, DistributorType, IDynamicDataControl, PageType } from '../../../constants/enums';
import { CacheDataService } from '../../../services/cache-data.service';
import { IStaticPage } from '../../../types/static-page';
export class StaticPageValidiator {
  constructor(private pageTypeHashKey: any, private cachedService: CacheDataService) {}
  isFormValid(staticPage: IStaticPage) {
    let isValid = true;
    let errors = [];
    if (!staticPage.pageTypeId) {
      isValid = false;
      errors.push('Select page type');
    }
    if (!staticPage.templateId) {
      isValid = false;
      errors.push('Select template');
    }
    if (
      !staticPage.distributerId &&
      this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'distributerId') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Select distributer');
    }
    if (
      !staticPage.companyId &&
      this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'companyId') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Select company');
    }

    if (!staticPage.icon) {
      isValid = false;
      errors.push('upload icon');
    }

    // if (
    //   !staticPage.browserId &&
    //   this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'browserId') == ControlState.Enable
    // ) {
    //   isValid = false;
    //   errors.push('Select browser');
    // }
    if (!staticPage.logo && this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'logo') == ControlState.Enable) {
      isValid = false;
      errors.push('upload logo');
    }
    if (
      !staticPage.extensionName &&
      this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'extensionName') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter extensionName');
    }
    if (!staticPage.domain && this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'domain') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter domain');
    }

    if (
      !staticPage.supportEmail &&
      this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'supportEmail') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter supportEmail');
    }

    if (
      !staticPage.appId &&
      this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'appId') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter valid App Web Id (greater than 0)');
    }

    if (
      !staticPage.pixelUrl &&
      this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'pixelUrl') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter pixelUrl');
    }
    if (
      !staticPage.headlineBackgroundColor &&
      this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'headlineBackgroundColor') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Select Headline BackgroundColor');
    }

    if (!staticPage.p1 && this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'p1') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter p1');
    }
    if (!staticPage.p2 && this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'p2') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter p2');
    }
    if (!staticPage.p3 && this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'p3') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter p3');
    }
    if (!staticPage.d1 && this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'd1') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter $1');
    }
    if (!staticPage.d2 && this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'd2') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter $2');
    }
    if (!staticPage.d3 && this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'd3') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter $3');
    }
    if (!staticPage.d4 && this.getControlState(staticPage.pageTypeId, staticPage.distributerId, staticPage.companyId, 'd4') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter $4');
    }
    return { isValid, errors };
  }
  getControlState(pageTypeId: number, distributerId: number, companyId: number, controlName: string) {
    let pageTypeKey: string = this.pageTypeHashKey[pageTypeId!];

    let pageTypeData = this.cachedService.extensionStaticPageControlConfig.find((x) => x.pageTypeId == pageTypeKey);

    let distributerIdKey: string = this.cachedService.distributorHaskey[distributerId!];
    let distributorsData = pageTypeData?.distributors.find((x) => x.distributorId == distributerIdKey);
    if (!distributorsData) {
      distributorsData = pageTypeData?.distributors.find((x) => x.distributorId == DistributorType.Any);
    }
    let companyKey: string = this.cachedService.comapnyHaskey[companyId!];
    let companyData = distributorsData?.companies?.find((x) => x.companyId == companyKey);
    if (!companyData) {
      companyData = distributorsData?.companies?.find((x) => x.companyId == CompanyType.Any);
    }
    if (controlName == 'distributerId') {
      //console.log('pageTypeData', pageTypeData);
      //console.log('distributorsData', distributerId, distributerIdKey, distributorsData);
      //console.log('companyData', companyId, companyKey, companyData);
      return pageTypeData?.distributorState;
    }
    if (controlName == 'companyId') {
      //  console.log('distributorsData', distributerId, distributerIdKey, distributorsData);
      //  console.log('companyData', companyId, companyKey, companyData);
      if (pageTypeData?.distributorState == ControlState.Hidden) return ControlState.Hidden;
      return distributorsData?.companyState;
    }

    let control = companyData?.constrols.find((x) => x.name == controlName);
    //console.log(pageTypeKey, distributerIdKey, companyKey, controlName, control);
    if (control && control.state) {
      // console.log(controlName,control.state)
      return control.state;
    }
    // console.log(controlName,ControlState.Hidden)
    return ControlState.Hidden;
  }
}

export const autoFileResolver = (controlData: IDynamicDataControl, staticPage: IStaticPage, cacheDataService: CacheDataService) => {
  switch (controlData.autoFillFrom) {
    case ControlNameType.Company:
      return cacheDataService.companyList.find((x) => x.companyId == staticPage.companyId || 0)?.companyName || '';

    case ControlNameType.ExtenstionName:
      return staticPage.extensionName || '';

    case ControlNameType.SupportMail:
      return staticPage.supportEmail || '';

    default:
      return controlData.value || '';
  }
};
export const replaceBeforeResolver = (controls: IDynamicDataControl[], staticPage: IStaticPage) => {
  controls?.forEach((element) => {
    if (element.replaceBeforeSave) {
      element.replaceBeforeSave.forEach((control) => {
        switch (control) {
          case ControlNameType.Domain:
            (staticPage as any)[element.name] = (staticPage as any)[element.name].replace('{$domain}', staticPage.domain);
            break;
          case ControlNameType.ExtenstionName:
              (staticPage as any)[element.name] = (staticPage as any)[element.name].replace('{$extensionName}', staticPage.extensionName);
              break;
        }
      });
    }
  });
};
