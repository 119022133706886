import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators ,ReactiveFormsModule} from '@angular/forms';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { MaterailModule } from '../../materail/materail.module';
import { CommonModule } from '@angular/common';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styles: [``],
  imports:[CommonModule,MaterailModule,FormsModule,ReactiveFormsModule,ToastrModule],
  standalone:true,
})
export class SignUpComponent {
  signupForm!: FormGroup;
  constructor(
    fb: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
    this.signupForm = fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
          ),
        ],
      ],
    });
  }
  signup() {
    const { name, email, password } = this.signupForm.value;
    this.authService.signup(name, email, password).subscribe(() => {
      this.notificationService.success('Signup sucessfully');
      this.signupForm.reset();
    });
  }
}
