<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
@if (this.authService.loggedIn && couldNotGetData) {
<div class="flex w-full justify-center p-4">
  <p class="bg-red-300 text-red-800 font-bold w-120 h-20 rounded shadow flex justify-center items-center p-8">
    Sorry our services are not available at this time, please refresh or try after sometime
  </p>
</div>
} @else if(!this.authService.loggedIn || (cacheDataService.topicList && cacheDataService.topicList.length)){
<router-outlet></router-outlet>
}@else{
<div class="flex justify-center items-center w-screen h-screen">
  <app-loading-placeholder></app-loading-placeholder>
</div>
}
