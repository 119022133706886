export enum TopicsType {
  ExtensionsStaticPages = 'Extensions Static Pages',
  ExtensionsLandingPages = 'Extensions Landing Pages',
  SearchDomainPages = 'Search Domain Pages',
  InstallerStaticPages = 'Installers Static Pages',
}

export enum PageType {
  //Static
  AboutUs = 'about us',
  Privacy = 'privacy',
  Terms = 'terms',
  Uninstall = 'uninstall',
  ThankYou = 'thankyou',
  GoodBye = 'goodbye',
  //Landing
  Compliance = 'compliance',
  Distribution = 'distribution',

  // Search Domain
  HomePage = 'homepage',
  SupportAndUninstall = 'support & uninstall',
  PrivacyPolicy = 'privacy',
  TermAndConditions = 'terms',
}

export enum DistributorType {
  Any = 'any',
  Yahoo = 'yahoo',
  Perion = 'perion',
  MediaNet = 'media net',
  Tempset = 'tempset',
}
export enum CompanyType {
  Any = 'any',
  GixMedia = 'gix media',
  Stanis = 'stanis',
  Lingofind = 'lingofind',
  Resoft = 'resoft',
  Viewbix = 'viewbix',
}

export interface BrowserType {
  browserId: number;
  browserName: string;
  browserStore: string;
  browserStoreLogo: string;
  browserLogo: string;
}

export interface IDynamicData {
  pageTypeId: PageType;
  distributors: IDynamicDataDistributor[];
  distributorState?: ControlState;
}
export interface IDynamicDataDistributor {
  distributorId: DistributorType;
  companyState?: ControlState;
  companies?: IDynamicDataCompany[];
}
export interface IDynamicDataCompany {
  companyId: CompanyType;
  constrols: IDynamicDataControl[];
}

export interface IDynamicDataControl {
  name: string;
  value?: string | number | boolean;
  state?: ControlState;
  autoFillFrom?: ControlNameType;
  replaceBeforeSave?: ControlNameType[];
  isReplaceBeforeSave?:boolean;
}

export enum ControlState {
  Enable = 'enable',
  Disable = 'disable',
  Hidden = 'hidden',
}
export enum ControlNameType {
  Company = 'company',
  ExtenstionName = 'extensionName',
  SupportMail = 'supportMail',
  BrowserName = 'browserName',
  ExtensionType = 'extensionType',
  Domain = 'domain',
  DomainName = 'domainName',
}

export enum BrowserName {
  Chrome = 'chrome',
  Edge = 'edge',
  Firefox = 'firefox',
  Safari = 'safari',
}

export const replaceBeforeSaveList: { from: string; to: ControlNameType }[] = [
  {
    from: '{$domain}',
    to: ControlNameType.Domain,
  },
  { from: '{$extensionName}', to: ControlNameType.ExtenstionName },
];
