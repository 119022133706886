import { environment } from "../environments/environment";

const config= {
  configurationUrl: {
    staticPage: `${environment.apiUrl}/api/Configuration/getExtensionStaticPagesConfiguration`,
    landingPage: `${environment.apiUrl}/api/Configuration/getExtensionLandingPagesConfiguration`,
    searchDomainPage: `${environment.apiUrl}/api/Configuration/getSearchDomainPagesConfiguration`,
  },
  configurationUrlLocal: {
    staticPage: `/assets/local/staticpagesconfig.json`,
    landingPage: `/assets/local/landingpagesconfig.json`,
    searchDomainPage: `/assets/local/searchdomainpagesconfig.json`,
  },
};
// config.configurationUrl={
//   ...config.configurationUrl,
//   ...config.configurationUrlLocal
// }
export default config
