import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { delay, retry } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/internal/operators/map';
import { environment } from '../../environments/environment.development';
import config from '../config';
import { BrowserType, IDynamicData } from '../constants/enums';
import { ICompany, IDistributor, ITopic, ListResultResponse } from '../types/auth-response';
import { ISearchPageConfiguration } from '../types/search-domain-page';
@Injectable({
  providedIn: 'root',
})
export class CacheDataService {
  brosersList!: BrowserType[];
  topicList!: ITopic[];
  distributorList!: IDistributor[];
  companyList!: ICompany[];
  extensionStaticPageControlConfig!: IDynamicData[];
  extensionLandingPageControlConfig!: IDynamicData[];
  searchDomainPageControlConfig!: ISearchPageConfiguration[];
  constructor(private http: HttpClient) {}

  init() {
    return forkJoin([
      this.getBrowsers().pipe(retry(1), delay(100)),
      this.getTopics().pipe(retry(1), delay(100)),
      this.getCompany().pipe(retry(1), delay(100)),
      this.getDistributors().pipe(retry(1), delay(100)),
      this.getExtensionStaticPageControlConfig().pipe(retry(1), delay(100)),
      this.getExtensionLandingPageControlConfig().pipe(retry(1), delay(100)),
      this.getSearchDomainPageControlConfig().pipe(retry(1), delay(100)),
    ]).toPromise();
  }
  getTopics() {
    if (this.topicList && this.topicList.length) {
      return of(this.topicList);
    }
    return this.http.get<ListResultResponse<ITopic>>(environment.apiUrl + '/api/Topic/getTopics').pipe(
      map((m) => {
        this.topicList = m.data;
        return m.data;
      })
    );
  }
  get topicHaskey() {
    let hash: any = {};
    this.topicList?.forEach((e) => {
      hash[e.topicName.trim()] = e.topicId;
    });
    return hash;
  }

  getBrowsers() {
    if (this.brosersList && this.brosersList.length) {
      return of(this.brosersList);
    }
    return this.http.get<ListResultResponse<BrowserType>>(environment.apiUrl + '/api/Browser/getBrowsers').pipe(
      map((m) => {
        this.brosersList = m.data;
        return m.data;
      })
    );
  }
  getDistributors() {
    if (this.distributorList && this.distributorList.length) {
      return of(this.distributorList);
    }
    return this.http.get<ListResultResponse<IDistributor>>(`${environment.apiUrl}/api/Distributer/getdistributer`).pipe(
      map((m) => {
        this.distributorList = m.data;
        return m.data;
      })
    );
  }
  get distributorHaskey() {
    let hash: any = {};
    this.distributorList?.forEach((e) => {
      hash[e.distributerId] = e.distributerName.toLowerCase().trim();
    });
    return hash;
  }
  getCompany() {
    if (this.companyList && this.companyList.length) {
      return of(this.companyList);
    }
    return this.http.get<ListResultResponse<ICompany>>(`${environment.apiUrl}/api/Company/getCompanies`).pipe(
      map((m) => {
        this.companyList = m.data;
        return m.data;
      })
    );
  }
  get comapnyHaskey() {
    let hash: any = {};
    this.companyList?.forEach((e) => {
      hash[e.companyId] = e.companyName.toLowerCase().trim();
    });
    return hash;
  }

  getExtensionStaticPageControlConfig() {
    if (this.extensionStaticPageControlConfig) {
      return of(this.extensionStaticPageControlConfig);
    }
    return this.http.get<IDynamicData[]>(`${config.configurationUrl.staticPage}`).pipe(
      map((m) => {
        console.info(m);
        this.extensionStaticPageControlConfig = m;
        return m;
      })
    );
  }
  getExtensionLandingPageControlConfig() {
    if (this.extensionLandingPageControlConfig) {
      return of(this.extensionLandingPageControlConfig);
    }
    return this.http.get<IDynamicData[]>(`${config.configurationUrl.landingPage}`).pipe(
      map((m) => {
        console.info(m);
        this.extensionLandingPageControlConfig = m;
        return m;
      })
    );
  }
  getSearchDomainPageControlConfig() {
    if (this.searchDomainPageControlConfig) {
      return of(this.searchDomainPageControlConfig);
    }
    return this.http.get<ISearchPageConfiguration[]>(`${config.configurationUrl.searchDomainPage}`).pipe(
      map((m) => {
        console.info(m);
        this.searchDomainPageControlConfig = m;
        return m;
      })
    );
  }
}
