import { Component } from '@angular/core';

@Component({
  selector: 'app-installer-static-pages',
  templateUrl: './installer-static-pages.component.html',
  styleUrls: ['./installer-static-pages.component.scss']
})
export class InstallerStaticPagesComponent {

}
