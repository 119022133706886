import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { BrowserType } from '../../../constants/enums';
import { CacheDataService } from '../../../services/cache-data.service';
import { MaterailModule } from '../../../materail/materail.module';

@Component({
  selector: 'app-select-browser',
  standalone: true,
  imports: [CommonModule, MaterailModule],
  templateUrl: './select-browser.component.html',
  styles: [
    `
      .check-circle {
        position: absolute;
        top: 2px;
        left: 2px;
        color: #fff;
        &:after {
          content: '✔';
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          line-height: 24px;
          font-size: 13px;
          text-align: center;
          background: #54c90a;
          border-radius: 50%;
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
        }
      }
      .browser-type-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        gap: 0.5rem;
        .browser-type {
          cursor: pointer;
          position: relative;
          font-size:12px;
          height: 55px;
          width: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #d2cccc;
          border-radius: 6px;
          opacity: 1;
          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .browser-img {
              height: 30px;
              width: 30px;
            }
          }
        }
      }
    `,
  ],
})
export class SelectBrowserComponent {
  cacheDataService = inject(CacheDataService);
  browsers: BrowserType[] = [];
  @Input('value') selectedBrowser: number | null = 0;
  @Input('values') selectedBrowsers: number[] | null = [];
  @Input('disabled') disabled = false;
  @Output() changed = new EventEmitter<any>();
  @Input() multiple = false;
  ngOnInit() {
    this.cacheDataService.getBrowsers().subscribe((x) => (this.browsers = x));
  }
  selected(browserId: number) {
    if (this.multiple) {
      if (!this.selectedBrowsers) {
        this.selectedBrowsers = [];
      }
      if (!this.selectedBrowsers!.includes(browserId)) this.selectedBrowsers!.push(browserId);
      else {
        this.selectedBrowsers = this.selectedBrowsers!.filter((x) => x != browserId);
      }
      this.changed.emit(this.selectedBrowsers);
    } else {
      this.selectedBrowser = browserId;
      this.changed.emit(this.selectedBrowser);
    }
  }
  isSelected(browserId: number) {
    if (this.multiple) {
      return this.selectedBrowsers!.includes(browserId);
    } else {
      return this.selectedBrowser == browserId;
    }
  }
}
