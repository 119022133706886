import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MaterailModule } from '../../../materail/materail.module';
import { CommonModule } from '@angular/common';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [MaterailModule, CommonModule],
  templateUrl: './table.component.html',
  styles: `.app-table-container{
        overflow-x: auto !important;
        width: 100%;
    text-align: left;
    position: relative;
    table{
      // table-layout:fixed !important;
    }
  }`,
})
export class TableComponent {
  @Input() data: any[] = [];
  @Input() total: number = 0;
  @Input() filter: any = {
    page: 0,
  };
  @Input() headers: { key: string; name: string; resolve?: Function; width?: string }[] = [];
  @Output() delete = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() publish = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @Output() onSorting = new EventEmitter<any>();
  @Output() onPaging = new EventEmitter<any>();
  @Input() isDelete = false;
  dataSource = new MatTableDataSource<any>([]);

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.paginator) this.paginator.pageIndex = this.filter.page || 0;
      if (this.paginator) this.paginator.length = this.total;
      this.dataSource.paginator = this.paginator;
    }, 0);
  }
  ngOnChanges() {
    if (this.paginator) this.paginator.pageIndex = this.filter.page;
    this.dataSource.data = this.data;
  }

  sortData(sort: Sort) {
    this.onSorting.emit(sort);
  }
  pageChange(pageEvent: PageEvent) {
    this.filter.page = pageEvent.pageIndex;
    this.filter.pageSize = pageEvent.pageSize;
    this.onPaging.emit(this.filter);
  }

  onDelete(row: any) {
    if(this.isDelete){
    this.delete.emit(row);
    }
  }
  onPublish(row: any) {
    this.publish.emit(row);
  }
  onEdit(row: any) {
    this.edit.emit(row);
  }
  get displayedColumns() {
    return this.headers.map((x) => x.key);
  }
}
