import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { TopicsType } from '../../constants/enums';
import { MaterailModule } from '../../materail/materail.module';
import { CacheDataService } from '../../services/cache-data.service';
import { HelperService } from '../../services/helper.service';
import { HttpService } from '../../services/http.service';
import { LoaderService } from '../../services/loader.service';
import { NotificationService } from '../../services/notification.service';
import { IPageType } from '../../types/auth-response';
import { ISearchDomainPage } from '../../types/search-domain-page';
import { ConfirmBoxComponent } from '../shared/confirm-box/confirm-box.component';
import { SearchFilterComponent } from '../shared/search-filter.component';
import { TableComponent } from '../shared/table/table.component';

@Component({
  selector: 'app-search-domain-pages',
  template: `
    <div class="page-header">
      <h2>Search Domain Pages</h2>
      <div class="filters">
        <app-search-filter (change)="searchChange($event)"></app-search-filter>
        <mat-form-field subscriptSizing="dynamic">
          <mat-select value="" (selectionChange)="publishFilter($event)">
            <mat-option value=""> All </mat-option>
            <mat-option value="1"> Published </mat-option>
            <mat-option value="2"> Unpublished </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button class="main-btn" (click)="addNewPage()">+ Add New Page</button>
      </div>
    </div>
    <app-table
      [data]="filteredPageList"
      [total]="totalPages"
      [headers]="headers"
      (publish)="publish($event)"
      (edit)="edit($event)"
      (onSorting)="sortData($event)"
    />
  `,
  styles: ``,
  imports: [CommonModule, MaterailModule, TableComponent, SearchFilterComponent],
  standalone: true,
})
export class SearchDomainPagesComponent {
  router = inject(Router);
  route = inject(ActivatedRoute);
  httpService = inject(HttpService);
  loader = inject(LoaderService);
  initialPageList!: ISearchDomainPage[];
  filteredPageList!: ISearchDomainPage[];
  totalPages!: number;
  pageTypes!: IPageType[];
  filter = {
    page: 0,
    search: '',
    published: '',
  };
  notificationService = inject(NotificationService);
  headers = [
    {
      key: 'pageTypeId',
      name: 'Page Type',
      resolve: (row: any) => {
        return this.pageTypes?.find((x) => x.pageTypeId == row.pageTypeId)?.pageTypeName || '';
      },
      width: '12%',
    },
    {
      key: 'productName',
      name: 'Product Name',
      width: '15%',
    },
    {
      key: 'updatedAt',
      name: 'Last Updated On',
      width: '15%',
      resolve: (row: any) => {
        let updateDate = row.updatedAt;
        if (updateDate && updateDate !== '') {
          let dateFormatStringMonth = new Date(updateDate).toString();
          let dateFormatNumbers = new Date(updateDate).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          });
          let timeFormatNumbers = new Date(updateDate).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
          });
          let splitedDate = dateFormatNumbers.split('/');
          let splitedHour = timeFormatNumbers.split(':');
          let month = dateFormatStringMonth.split(' ')[1];
          let day = splitedDate[1];
          let year = splitedDate[2];
          let hour = splitedHour[0];
          let minutes = splitedHour[1];
          let stringDate = `${day} ${month} ${year} | ${hour}:${minutes}`;
          return stringDate;
        }
        return updateDate;
      },
    },
    {
      key: 'published',
      name: 'Published',
      width: '12%',
      resolve: (row: any, coloum: any) => {
        if (row.published) {
          return 'Yes';
        }
        return 'No';
      },
    },
    {
      key: 'publishedUrl',
      name: 'Published Url',
    },
    {
      key: 'action',
      name: 'Action',
      width: '15%',
    },
  ];
  dialog = inject(MatDialog);
  helperService = inject(HelperService);
  cacheDataService = inject(CacheDataService);
  ngOnInit() {
    this.fetchPages();
    this.httpService.getPageTypeByTopic(this.cacheDataService.topicHaskey[TopicsType.SearchDomainPages]).subscribe((result) => {
      this.pageTypes = result.data;
    });
  }
  private fetchPages() {
    this.loader.show();
    this.httpService.getSearchDomainPages().subscribe((result) => {
      this.initialPageList = result.data;
      this.filteredPageList = result.data;
      this.totalPages = result.totalMatchRecords;
      this.loader.hide();
    });
  }
  searchChange(search: string) {
    console.log(search);
    if (typeof search == 'string') {
      this.filter.search = search;
      this.onFilter();
    }
  }
  publishFilter(event: MatSelectChange) {
    this.filter.published = event.value;
    this.onFilter();
  }
  onFilter() {
    console.log(this.filter);
    let filteredData: ISearchDomainPage[] = this.initialPageList;
    if (this.filter.published) {
      filteredData = filteredData.filter((x) => (+this.filter.published == 1 && x.published == true) || (+this.filter.published == 2 && x.published == false));
    }
    if (this.filter.search) {
      filteredData = filteredData.filter((x) => x.publishedUrl?.toLowerCase().includes(this.filter.search?.toLowerCase()));
    }
    this.filteredPageList = filteredData;
    this.totalPages = filteredData.length;
    this.filter.page = 0;
  }
  addNewPage() {
    this.router.navigateByUrl('/search-domains/add');
  }
  edit(row: ISearchDomainPage) {
    this.router.navigateByUrl('/search-domains/' + row.searchDomainStaticPageId);
  }
  publish(row: ISearchDomainPage) {
    this.loader.show();
    this.httpService.publishSearchDomainPage(+row.templateId!, +row.searchDomainStaticPageId!).subscribe({
      next: (result) => {
        if (result.isPublishedSucceeded) window.open(result.publishedUrl);
        this.notificationService.success('Page published successfully');
        this.loader.hide();
        this.fetchPages();
      },
    });
  }

  sortData(sort: Sort) {
    const data = this.filteredPageList.slice();
    if (!sort.active || sort.direction === '') {
      this.filteredPageList = data;
      return;
    }

    this.filteredPageList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'pageTypeId':
          return this.helperService.compareString(
            this.pageTypes.find((x) => x.pageTypeId == a.pageTypeId)?.pageTypeName || '',
            this.pageTypes.find((x) => x.pageTypeId == b.pageTypeId)?.pageTypeName || '',
            isAsc
          );
        case 'pageName':
          return this.helperService.compareString(a.pageName, b.pageName, isAsc);
        case 'productName':
          return this.helperService.compareString(a.pageName, b.pageName, isAsc);
        case 'publishedUrl':
          return this.helperService.compareString(a.publishedUrl, b.publishedUrl, isAsc);
        case 'published':
          return this.helperService.compareString(a.published ? 'Yes' : 'No', b.published ? 'Yes' : 'No', isAsc);
        case 'updatedAt':
          return this.helperService.compareDate(a.updatedAt, b.updatedAt, isAsc);
        default:
          return 0;
      }
    });
  }
}
