import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';

function excludeNewTokenRefresh(actionUrl: string) {
  const urls = ['/auth/login', '/auth/newtoken', '/browser/getbrowsers', '/topic/gettopics', '/company/getcompanies', '/distributer/getdistributer'];
  for (let u of urls) {
    if (actionUrl.toLowerCase().includes(u.toLowerCase())) {
      return true;
    }
  }
  return false;
}
export const tokenInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const toaster = inject(ToastrService);
  const authService = inject(AuthService);
  const token = authService.getToken();
  if (token) {
    // If we have a token, we set it to the header
    request = request.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
    if (!excludeNewTokenRefresh(request.url)) {
      authService.refreshToken.next();
    }
  }

  return next(request);
};
