import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { ErrorResponse } from '../types/error-response';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../services/notification.service';
import { LoaderService } from '../services/loader.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

export const httpErrorInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const notificationService = inject(NotificationService);
  const loaderService = inject(LoaderService);
  const authService = inject(AuthService);
  const router = inject(Router);
  return next(request).pipe(
    catchError((err: any) => {
      loaderService.hide(20);
      if (err instanceof HttpErrorResponse) {
        if (err.status == HttpStatusCode.Unauthorized) {
          authService.logout();
          notificationService.error('You have been logged out', 'Error!');
          router.navigateByUrl('/login');
        } else if (err.status == HttpStatusCode.BadGateway || err.status == HttpStatusCode.ServiceUnavailable) {
          authService.logout();
          router.navigateByUrl('/login');
        } else {
          const response: ErrorResponse = err.error;
          if (response && response.message) notificationService.error(response.message, 'Error!');
          else if (response && response.errors) {
            let errors = [];
            console.warn(response.errors);
            for (let k in  response.errors) {
              let e= response.errors[k];
              if (Array.isArray(e)) {
                errors.push(...e);
              } else if (typeof e == 'string') {
                errors.push(e);
              }

            }
            notificationService.error(errors.join(", "), 'Error!');
          }
        }
      }
      if (!err.error) err.error = {};
      return throwError(() => err.error);
    })
  );
};
