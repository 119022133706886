import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSelectSearchModule } from 'mat-select-search';
import { MaterailModule } from '../../../materail/materail.module';

import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CompanyType, ControlNameType, ControlState, DistributorType, IDynamicDataDistributor, PageType, TopicsType } from '../../../constants/enums';
import { HttpService } from '../../../services/http.service';

import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, map } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { CacheDataService } from '../../../services/cache-data.service';
import { LoaderService } from '../../../services/loader.service';
import { NotificationService } from '../../../services/notification.service';
import { ICompany, IDistributor, IPageType, ITemplate } from '../../../types/auth-response';
import { IScript, IStaticPage } from '../../../types/static-page';
import { FileUploadComponent } from '../../shared/file-upload/file-upload.component';
import { LoadingPlaceholderComponent } from '../../shared/loading-placeholder/loading-placeholder.component';
import { ScriptsInputComponent } from '../../shared/scripts-input/scripts-input.component';
import { TemplatePreviewComponent } from '../../shared/template-preview/template-preview.component';
import { StaticPageValidiator, autoFileResolver, replaceBeforeResolver } from './static-page-common';
import { ColorPickerModule } from 'ngx-color-picker';
import { scriptTypes } from '../../shared/script-types';
import { scriptRegex, noscriptRegex } from '../../shared/regex-definitions';

@Component({
  selector: 'app-static-page-form',
  templateUrl: './static-page-form.component.html',
  imports: [
    CommonModule,
    MaterailModule,
    MatSelectSearchModule,
    ScriptsInputComponent,
    FormsModule,
    ReactiveFormsModule,
    LoadingPlaceholderComponent,
    TemplatePreviewComponent,
    FileUploadComponent,
    ColorPickerModule,
  ],
  styles: [],
  standalone: true,
})
export class StaticPageFormComponent {  
  router = inject(Router);
  route = inject(ActivatedRoute);
  httpService = inject(HttpService);
  cacheDataService = inject(CacheDataService);
  formBuilder = inject(FormBuilder);
  loader = inject(LoaderService);
  dialog = inject(MatDialog);
  overlay = inject(Overlay);
  notificationService = inject(NotificationService);
  pageId: string | null = null;
  list = [
    { id: '1', name: 'Test 1' },
    { id: '2', name: 'Test 2' },
  ];
  filterdList: any = [];
  pageTypes: IPageType[] = [];
  PageTypeEnum = PageType;
  templateTypes: ITemplate[] = [];
  distributors: IDistributor[] = [];
  companies: ICompany[] = [];  
  scriptTypes: string[] = scriptTypes;
  templatesLoading = false;
  editMode = false;
  pageForm = this.formBuilder.group({
    pageTypeId: [0],
    templateId: [0],
    //browserId: [0],
    distributerId: [0],
    companyId: [0],
    domain: [''],
    supportEmail: [''],
    extensionName: [''],
    pageName: [''],
    productName: [''],
    appId: [0],
    icon: [''],
    logo: [''],
    pixelUrl: [''],
    p1: [''],
    p2: [''],
    p3: [''],
    headlineBackgroundColor: [''],
    d1: [''],
    d2: [''],
    d3: [''],
    d4: [''],
    scripts: this.formBuilder.array([]),
  });
  isScriptOpen=false;
  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(map((results) => ({ params: results[0], query: results[1] })))
      .subscribe((results) => {
        this.pageId = results.params['id'] || null;
        const editmode = results.query['editmode'] || 'true';
        console.log('nginit', this.pageId, editmode);
        if (this.pageId) {
          this.initEdit(this.pageId, editmode == 'true');
        } else {
          this.initAdd();
        }
      });
  }

  initAdd() {
    this.editMode = true;
    this.loader.show();
    this.httpService.getPageTypeByTopic(this.cacheDataService.topicHaskey[TopicsType.ExtensionsStaticPages]).subscribe((result) => {
      this.pageTypes = result.data;
      this.loader.hide();
    });
    this.addScriptRow({ edit: true }, 0);
    this.setControlState(true);
    this.handleValueChanges();
  }
  initEdit(id: string, editMode: boolean) {
    this.loader.show();
    const pageTypes = this.httpService.getPageTypeByTopic(this.cacheDataService.topicHaskey[TopicsType.ExtensionsStaticPages]);
    const pageDetail = this.httpService.getStaticPage(+id);
    forkJoin([pageTypes, pageDetail]).subscribe({
      next: (results) => {
        this.pageTypes = results[0].data;
        const formValues = results[1].data[0];
        for (let i = 0; i < formValues.scripts!?.length; i++) {
          this.addScriptRow({ edit: false }, i);
        }
        //this.addScriptRow(null, formValues.scripts!?.length);
        this.pageForm.patchValue(formValues);
        this.pageForm.controls.pageTypeId.disable();
        this.loader.hide();
        this.editMode = editMode;
        this.addScriptRow({ edit: true }, formValues.scripts!?.length);
        this.bindTemplateType(this.pageForm.controls.pageTypeId.value || 0);
        this.bindDistributors(this.pageForm.controls.pageTypeId.value || 0);
        this.bindCompanies(this.pageForm.controls.pageTypeId.value || 0, this.pageForm.controls.distributerId.value || 0);
        this.setControlState(false);
        setTimeout(() => {
          this.handleValueChanges();
        });

        if (!editMode) {
          setTimeout(() => {
             this.pageForm.disable({emitEvent:false})
          }, 1000);
        }
      },
      complete: () => {
        this.loader.hide();
      },
    });
  }
  get pageTypeHaskey() {
    let hash: any = {};
    this.pageTypes.forEach((e) => {
      hash[e.pageTypeId] = e.pageTypeName.toLowerCase().trim();
    });
    return hash;
  }

  showControl(controlName: string) {
    const pageTypeId = +(this.pageForm.controls.pageTypeId.value || 0);
    const distributerId = +(this.pageForm.controls.distributerId.value || 0);
    const companyId = +(this.pageForm.controls.companyId.value || 0);
    const staticPageValidiator = new StaticPageValidiator(this.pageTypeHaskey, this.cacheDataService);
    return staticPageValidiator.getControlState(pageTypeId, distributerId, companyId, controlName) != ControlState.Hidden;
  }
  get isDistributerHidden(){
    const pageTypeId = +(this.pageForm.controls.pageTypeId.value || 0);
    const distributerId = +(this.pageForm.controls.distributerId.value || 0);
    const companyId = +(this.pageForm.controls.companyId.value || 0);
    const staticPageValidiator = new StaticPageValidiator(this.pageTypeHaskey, this.cacheDataService);
    return staticPageValidiator.getControlState(pageTypeId, distributerId, companyId, 'distributerId') == ControlState.Hidden;
  }
  isRequired(controlName: string) {
    const pageTypeId = +(this.pageForm.controls.pageTypeId.value || 0);
    const distributerId = +(this.pageForm.controls.distributerId.value || 0);
    const companyId = +(this.pageForm.controls.companyId.value || 0);
    const staticPageValidiator = new StaticPageValidiator(this.pageTypeHaskey, this.cacheDataService);
    return staticPageValidiator.getControlState(pageTypeId, distributerId, companyId, controlName) == ControlState.Enable;
  }
  get isUninstallPage() {
    return this.pageTypeHaskey[this.pageForm.value.pageTypeId!] == PageType.Uninstall;
  }
  // browserSelected(browserId: number) {
  //   this.pageForm.controls.browserId.setValue(browserId);
  // }
  async iconUploaded(url: string) {
    this.pageForm.controls.icon.setValue(url);
  }
  async logoUpload(url: string) {
    this.pageForm.controls.logo.setValue(url);
  }

  addScriptRow(value?: any, index?: any) {
    if (value) {
      let a = this.formBuilder.group({
        scriptName: value.scriptName || '',
        scriptType: value.scriptType || '',
        scriptContent: value.scriptContent || '',
        edit: value.edit || false,
      });
      if (!value.edit) {
        a.disable();
      }
      this.scriptsControl.push(a);
    } else {
      if (this.scriptsControl.length > 0) {
        let row = this.scriptsControl[index];
        let x = row.value;
        if (!this.validiateScript(x)) {
          return;
        }
        row.patchValue({
          edit: false,
        });
        row.disable();
      }
      let a = this.formBuilder.group({
        scriptName: '',
        scriptType: '',
        scriptContent: '',
        edit: true,
      });
      this.scriptsControl.push(a);
    }
  }
  validiateScript(script: IScript) {
    let errors: string[] = [];
    if (!script.scriptName) {
      errors.push('Enter script name.');
    }
    if (!script.scriptType) {
      errors.push('Enter script type.');
    }
    if (!script.scriptContent) {
      errors.push('Enter script content.');
    }    
    if(script.scriptType == 'Begin of Body'){
      if(!noscriptRegex.test(script.scriptContent)){
        errors.push('Not valid script content was added. For this script type \'noscript\' tag is required.');
      }
    }
    else {
      if(!scriptRegex.test(script.scriptContent)) {
        errors.push('Not valid script content was added. For this script type \'script\' tag is required.');
      }
    }   

    if (errors.length) {
      this.notificationService.showErros(errors);
      return false;
    }
    return true;
  }
  removeScript(i: number) {
    this.scriptsControl.splice(i, 1);
  }
  editScript(i: number) {
    let row = this.scriptsControl[i];
    row.patchValue({
      edit: true,
    });
    row.enable();
    row.markAsPristine();
  }
  updateScript(i: number) {
    let row = this.scriptsControl[i];
    let x = row.value;
    if (!this.validiateScript(x)) {
      return;
    }
    row.patchValue({
      edit: false,
    });
    row.disable();
  }
  add() {
    let formValue: any = this.pageForm.getRawValue();

    formValue.pageName = formValue.extensionName + ' ' + this.pageTypes.find((x) => x.pageTypeId == formValue.pageTypeId)?.pageTypeName;
    formValue.productName = formValue.extensionName;
    formValue.scripts = this.scriptsControl.map((x) => x.value).filter((x) => x.scriptName && x.scriptContent && x.scriptType);
    const staticPageValidiator = new StaticPageValidiator(this.pageTypeHaskey, this.cacheDataService);
    const result = staticPageValidiator.isFormValid(formValue);

    if (!result.isValid) {
      this.notificationService.showErros(result.errors);
      return;
    }
    if (this.isScriptUnsaved()) {
      return this.notificationService.showErros(['You have unsaved scripts']);
    }
    if (!formValue.distributerId) {
      formValue.distributerId = +this.cacheDataService.distributorList.find((x) => x.distributerName.toLocaleLowerCase() == 'general')!.distributerId;
    }
    if (!formValue.companyId) {
      formValue.companyId = +this.cacheDataService.companyList.find((x) => x.companyName.toLocaleLowerCase() == 'general')!.companyId;
    }
    replaceBeforeResolver(this.controlsData, formValue);
    formValue = this.removeBeforeSave(formValue);
    this.loader.show();
    this.httpService.addStaticPage(formValue).subscribe((result) => {
      this.loader.hide();
      if (result && result.id) {
        this.notificationService.success('Page saved sucessfully');
        this.router.navigateByUrl('extension-statics/' + result.id + '?editmode=false');
      }
    });
  }
  isScriptUnsaved() {
    console.log(this.scriptsControl);
    let unsaved = false;
    this.scriptsControl.forEach((value, i) => {
      if (i != this.scriptsControl.length - 1 && value.status != 'DISABLED' && value.dirty) {
        unsaved = true;
      }
      if (i == this.scriptsControl.length - 1 && (value.value.scriptName || value.value.scriptType || value.value.scriptContent)) {
        unsaved = true;
      }
    });
    if (!unsaved) {
      this.scriptsControl.forEach((value, i) => {
        if (i != this.scriptsControl.length - 1) {
          value.patchValue({
            edit: false,
          });
          value.disable();
        }
      });
    }
    return unsaved;
  }
  edit() {
    this.editMode = true;
    this.pageForm.enable({ emitEvent: false });
    this.scriptsControl?.forEach((value, i) => {
      if (i != this.scriptsControl.length - 1) value.disable();
    });
    setTimeout(() => {
      this.pageForm.controls.pageTypeId.disable({ emitEvent: false });
      this.setControlState(false);
    });
  }
  update() {
    let formValue: any = this.pageForm.getRawValue();
    formValue.pageName = formValue.extensionName + ' ' + this.pageTypes.find((x) => x.pageTypeId == formValue.pageTypeId)?.pageTypeName;
    formValue.productName = formValue.extensionName;
    formValue.scripts = this.scriptsControl.map((x) => x.value).filter((x) => x.scriptName && x.scriptContent && x.scriptType);

    const staticPageValidiator = new StaticPageValidiator(this.pageTypeHaskey, this.cacheDataService);
    const result = staticPageValidiator.isFormValid(formValue);
    if (!result.isValid) {
      this.notificationService.showErros(result.errors);
      return;
    }
    if (this.isScriptUnsaved()) {
      return this.notificationService.showErros(['You have unsaved scripts']);
    }
    this.loader.show();
    replaceBeforeResolver(this.controlsData, formValue);
    formValue = this.removeBeforeSave(formValue);
    this.httpService.updateStaticPage(+this.pageId!, formValue).subscribe((result) => {
      this.loader.hide();
      this.notificationService.success('Page updated sucessfully');
      this.editMode = false;
      this.pageForm.disable({ emitEvent: false });
    });
  }
  removeBeforeSave(formValue: any) {
    for (let key in formValue) {
      if (['pageTypeId', 'templateId', 'distributerId', 'companyId', 'pageName', 'productName', 'icon', 'scripts'].includes(key)) continue;
      if (!this.showControl(key)) {
        console.log(key, typeof formValue[key]);
        if (typeof formValue[key] == 'string') {
          formValue[key] = '';
        } else if (typeof formValue[key] == 'number') {
          formValue[key] = 0;
        } else {
          formValue[key] = null;
        }
      }
    }
    return formValue;
  }
  publish() {
    this.loader.show();
    this.httpService.publishStaticPage(+this.pageForm.value.templateId!, +this.pageId!).subscribe({
      next: (result) => {
        if (result.isPublishedSucceeded) window.open(result.publishedUrl);
        this.notificationService.success('Page published sucessfully');
        this.router.navigateByUrl('extension-statics');
        this.loader.hide();
      },
    });
  }
  previewPage() {
    let template = this.templateTypes.find((x) => x.templateId == this.pageForm.controls.templateId.value!);
    console.log(template);
    let scrollStrategy = this.overlay.scrollStrategies.reposition();
    this.dialog.open(TemplatePreviewComponent, {
      width: '80vw',
      height: '80vh',
      panelClass: 'template-preview',
      scrollStrategy: scrollStrategy,
      data: {
        template: template,
      },
    });
  }
  get scriptsControl() {
    return (this.pageForm.get('scripts') as FormArray).controls;
  }

  bindTemplateType(pageTypeId?: number) {
    this.templatesLoading = true;
    this.httpService.getTemplateByPagetype(pageTypeId).subscribe({
      next: (result: any) => {
        this.templateTypes = result.data;
        this.templatesLoading = false;
      },
    });
  }
  bindDistributors(pageTypeId: number) {
    let pageTypeKey = this.pageTypeHaskey[pageTypeId!];
    this.distributors = this.cacheDataService.distributorList.filter((x) => {
      if (
        this.cacheDataService.extensionStaticPageControlConfig
          .find((x) => x.pageTypeId == pageTypeKey)
          ?.distributors.find((y) => y.distributorId == x.distributerName.toLowerCase().trim())
      ) {
        return true;
      }
      return false;
    });
  }
  get distributorData(): IDynamicDataDistributor | null {
    let pageTypeKey: string = this.pageTypeHaskey[this.pageForm.controls.pageTypeId.value!];
    let distributerIdKey: string = this.cacheDataService.distributorHaskey[this.pageForm.controls.distributerId.value!];
    let companyKey: string = this.cacheDataService.comapnyHaskey[this.pageForm.controls.companyId.value!];
    let pageTypeData = this.cacheDataService.extensionStaticPageControlConfig.find((x) => x.pageTypeId == pageTypeKey);
    let distributorsData = pageTypeData?.distributors.find((x) => x.distributorId == distributerIdKey);
    if (!distributorsData) {
      distributorsData = pageTypeData?.distributors.find((x) => x.distributorId == DistributorType.Any);
    }
    return distributorsData || null;
  }
  get controlsData() {
    let pageTypeKey: string = this.pageTypeHaskey[this.pageForm.controls.pageTypeId.value!];
    if (!pageTypeKey) return [];
    let distributerIdKey: string = this.cacheDataService.distributorHaskey[this.pageForm.controls.distributerId.value!];
    let companyKey: string = this.cacheDataService.comapnyHaskey[this.pageForm.controls.companyId.value!];
    console.log(pageTypeKey, distributerIdKey, companyKey, this.cacheDataService.extensionStaticPageControlConfig);
    let pageTypeData = this.cacheDataService.extensionStaticPageControlConfig.find((x) => x.pageTypeId == pageTypeKey);
    let distributorsData = pageTypeData?.distributors.find((x) => x.distributorId == distributerIdKey);
    if (!distributorsData) {
      distributorsData = pageTypeData?.distributors.find((x) => x.distributorId == DistributorType.Any);
    }
    let companyData = distributorsData?.companies?.find((x) => x.companyId == companyKey);
    if (!companyData) {
      companyData = distributorsData?.companies?.find((x) => x.companyId == CompanyType.Any);
    }
    return companyData?.constrols || [];
  }
  bindCompanies(pageTypeId: number, distributorId: number) {
    let pageTypeKey = this.pageTypeHaskey[pageTypeId!];
    let distributorKey = this.cacheDataService.distributorHaskey[distributorId!];
    let pageTypeData = this.cacheDataService.extensionStaticPageControlConfig.find((x) => x.pageTypeId == pageTypeKey);
    let distributorsData = pageTypeData?.distributors.find((x) => x.distributorId == distributorKey);
    this.companies = this.cacheDataService.companyList.filter((x) => {
      if (distributorsData?.companies?.find((y) => y.companyId == x.companyName.toLowerCase().trim())) {
        return true;
      }
      return false;
    });
    const firstCompanyData = distributorsData?.companies![0];
    const value = this.cacheDataService.companyList.find((y) => y.companyName.toLowerCase().trim() == firstCompanyData?.companyId)?.companyId || 0;
    console.log('firstCompanyData', firstCompanyData, distributorsData);
    // console.log('companies', this.companies, value);
    this.pageForm.controls.companyId.enable({ emitEvent: false });
    setTimeout(() => {
      this.pageForm.controls.companyId.setValue(value);
      this.setCompanyState();
    });
  }
  setCompanyState() {
    setTimeout(() => {
      if (this.distributorData?.companyState == ControlState.Enable) {
        this.pageForm.controls.companyId.enable({ emitEvent: false });
      } else {
        this.pageForm.controls.companyId.disable({ emitEvent: false });
      }
    });
  }
  handleValueChanges() {
    this.pageForm.controls.pageTypeId.valueChanges.subscribe((value) => {
      console.log('pageTypeId', value);
      this.pageForm.patchValue({
        templateId: 0,
        distributerId: 0,
        companyId: 0,
      });
      this.bindTemplateType(value!);
      this.bindDistributors(value!);
    });
    this.pageForm.controls.templateId.valueChanges.subscribe((value) => {
      //console.log('templateId', value);
      // if (PageType.Uninstall == this.pageTypeHaskey[this.pageTypeId]) {
      //   let templateName = this.templateTypes.find((x) => x.templateId == value)?.templateName;
      //   console.log('template change page', value, templateName);
      //   let browserId = this.cacheDataService.brosersList.find((x) => templateName?.toLowerCase().includes(x.browserName.toLowerCase()))?.browserId;
      //   this.pageForm.controls.browserId.setValue(browserId || 0,{emitEvent:false});
      // }
    });
    this.pageForm.controls.distributerId.valueChanges.subscribe((value) => {
      //console.log('distributerId', value);
      this.bindCompanies(this.pageForm.controls.pageTypeId.value!, this.pageForm.controls.distributerId.value!);
    });
    this.pageForm.controls.companyId.valueChanges.subscribe((value) => {
      // console.log('companyId.valueChanges');
      this.setControlState(true);
    });

    this.pageForm.controls.supportEmail.valueChanges.subscribe((value) => {
      this.controlsData
        .filter((x) => x.autoFillFrom == ControlNameType.SupportMail)
        .forEach((control) => {
          this.pageForm.get(control.name)?.setValue(value);
        });
    });

    this.pageForm.controls.extensionName.valueChanges.subscribe((value) => {
      this.controlsData
        .filter((x) => x.autoFillFrom == ControlNameType.ExtenstionName)
        .forEach((control) => {
          this.pageForm.get(control.name)?.setValue(value);
        });
    });

    // this.pageForm.controls.browserId.valueChanges.subscribe((value) => {
    //   let browserName = this.cacheDataService.brosersList.find((x) => x.browserId == value)?.browserName || '';
    //   this.controlsData
    //     .filter((x) => x.autoFillFrom == ControlNameType.BrowserName)
    //     .forEach((control) => {
    //       this.pageForm.get(control.name)?.setValue(browserName);
    //     });
    //   if (PageType.Uninstall == this.pageTypeHaskey[this.pageTypeId]) {
    //     console.log('browser change page', value, browserName);
    //     let tId = this.templateTypes.find((x) => x.templateName.toLowerCase().includes(browserName.toLowerCase()))?.templateId;
    //     this.pageForm.controls.templateId.setValue(tId || 0,{emitEvent:false});
    //   }
    // });
  }
  get pageTypeId() {
    return this.pageForm.controls.pageTypeId.value || 0;
  }
  private setControlState(updateValue: boolean) {
    console.log('this.controlsData', this.controlsData);
    this.controlsData?.forEach((element) => {
      let formControl = this.pageForm.get(element.name);
      if (updateValue) {
        formControl?.setValue(autoFileResolver(element, this.pageForm.getRawValue() as IStaticPage, this.cacheDataService));
      }
      switch (element.state) {
        case ControlState.Hidden:
          formControl?.disable({ emitEvent: false });
          break;
        case ControlState.Enable:
          formControl?.enable({ emitEvent: false });
          break;
        case ControlState.Disable:
          formControl?.disable({ emitEvent: false });
          break;
      }
    });
  }
}
