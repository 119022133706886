import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MaterailModule } from '../../../materail/materail.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-confirm-box',
  templateUrl: './confirm-box.component.html',
  styles: [],
  imports: [MaterailModule, CommonModule],
  standalone: true,
})
export class ConfirmBoxComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmBoxComponent>, @Inject(MAT_DIALOG_DATA) public data: IConfimrationBoxData) {}
  DialogBoxType = DialogBoxType;
  yes(): void {
    this.dialogRef.close({
      response: true,
    });
  }
  no(): void {
    this.dialogRef.close({
      response: false,
    });
  }
  ok(): void {
    this.dialogRef.close();
  }
}
export interface IConfimrationBoxData {
  message?: string;
  errors?: string[];
  type: number | DialogBoxType;
}

export enum DialogBoxType {
  confirmation = 1,
  error = 2,
}
