import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './header.component';


@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet,HeaderComponent],
  templateUrl: './layout.component.html',
  styles: [
    `
      .root-container {
        padding: 0 4rem;
        display: flex;
        justify-content: center;
        .page-container {
          max-width: 1600px;
          width: 100%;
          .main-content {
            padding: 1rem 4.8rem;
          }
        }
      }
    `,
  ],
})
export class LayoutComponent {}
