import { ControlNameType, CompanyType, ControlState, DistributorType, IDynamicDataControl, BrowserName, PageType } from '../../../constants/enums';
import { CacheDataService } from '../../../services/cache-data.service';
import { ILandingPage } from '../../../types/landing-page';
export class LandingPageValidiator {
  constructor(private pageTypeHashKey: any, private cachedService: CacheDataService, private landingPage: ILandingPage, private pageTypeName: PageType) {}
  isFormValid() {
    let landingPage = this.landingPage;
    let isValid = true;
    let errors = [];
    if (!landingPage.pageTypeId) {
      isValid = false;
      errors.push('Select page type');
    }
    if (!landingPage.templateId) {
      isValid = false;
      errors.push('Select template');
    }
    if (!landingPage.icon) {
      isValid = false;
      errors.push('upload icon');
    }
    if (
      !landingPage.distributionSubDomain &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'distributionSubDomain') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter subDomain');
    }

    if (
      !(landingPage.browserIds && landingPage.browserIds.length) &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'browserId') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Select browser');
    }
    if (!landingPage.logo && this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'logo') == ControlState.Enable) {
      isValid = false;
      errors.push('upload logo');
    }
    if (
      !landingPage.extensionName &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'extensionName') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter extensionName');
    }
    if (
      !landingPage.domain &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'domain') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter domain');
    }

    if (
      !landingPage.supportEmail &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'supportEmail') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter supportEmail');
    }
    if (
      !landingPage.browserName &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'browserName') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter browserName');
    }

    if (
      !landingPage.productName &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'productName') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter productName');
    }

    if (!landingPage.appId && this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'appId') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter App Web Id (greater than 0)');
    }
    // if (
    //   !landingPage.chromeAppId &&
    //   this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'chromeAppId') == ControlState.Enable
    // ) {
    //   isValid = false;
    //   errors.push('Enter Chrome app store extension id');
    // }

    // if (
    //   !landingPage.edgeAppId &&
    //   this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'edgeAppId') == ControlState.Enable
    // ) {
    //   isValid = false;
    //   errors.push('Enter Edge app store extension id');
    // }

    // if (
    //   !landingPage.firefoxAppId &&
    //   this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'firefoxAppId') == ControlState.Enable
    // ) {
    //   isValid = false;
    //   errors.push('Enter Firefox AddOns extension url');
    // }

    if (
      !landingPage.extensionType &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'extensionType') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter extensionType');
    }

    if (
      !landingPage.buttonColor &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'buttonColor') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter buttonColor');
    }

    if (
      !landingPage.sectionTwoImage &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'sectionTwoImage') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter sectionTwoImage');
    }

    if (
      !landingPage.sectionThreeColor &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'sectionThreeColor') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter sectionThreeColor');
    }

    if (
      !landingPage.sectionFourColor &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'sectionFourColor') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Enter sectionFourColor');
    }

    if (
      !landingPage.sectionOneArrowImage &&
      this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'sectionOneArrowImage') == ControlState.Enable
    ) {
      isValid = false;
      errors.push('Upload SectionOneArrow Image');
    }

    if (!landingPage.p1 && this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'p1') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter p1');
    }
    if (!landingPage.p2 && this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'p2') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter p2');
    }

    if (!landingPage.d1 && this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'd1') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter $1');
    }
    if (!landingPage.d2 && this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'd2') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter $2');
    }
    if (!landingPage.d3 && this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'd3') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter $3');
    }
    if (!landingPage.d4 && this.getControlState(landingPage.pageTypeId, landingPage.distributerId, landingPage.companyId, 'd4') == ControlState.Enable) {
      isValid = false;
      errors.push('Enter $4');
    }
    return { isValid, errors };
  }
  getControlState(pageTypeId: number, distributerId: number, companyId: number, controlName: string) {
    let pageTypeKey: string = this.pageTypeHashKey[pageTypeId!];

    let pageTypeData = this.cachedService.extensionLandingPageControlConfig.find((x) => x.pageTypeId == pageTypeKey);

    let distributerIdKey: string = this.cachedService.distributorHaskey[distributerId!];
    let distributorsData = pageTypeData?.distributors.find((x) => x.distributorId == distributerIdKey);
    if (!distributorsData) {
      distributorsData = pageTypeData?.distributors.find((x) => x.distributorId == DistributorType.Any);
    }
    let companyKey: string = this.cachedService.comapnyHaskey[companyId!];
    let companyData = distributorsData?.companies?.find((x) => x.companyId == companyKey);
    if (!companyData) {
      companyData = distributorsData?.companies?.find((x) => x.companyId == CompanyType.Any);
    }
    if (controlName == 'distributerId') {
      return pageTypeData?.distributorState;
    }
    if (controlName == 'companyId') {
      if (pageTypeData?.distributorState == ControlState.Hidden) return ControlState.Hidden;
      return distributorsData?.companyState;
    }
    if (
      controlName == 'chromeAppId' &&
      !this.landingPage?.browserIds.includes(this.cachedService.brosersList?.find((x) => x.browserName.toLowerCase().includes(BrowserName.Chrome))?.browserId!)
    ) {
      return ControlState.Hidden;
    }
    if (
      controlName == 'edgeAppId' &&
      !this.landingPage?.browserIds.includes(this.cachedService.brosersList?.find((x) => x.browserName.toLowerCase().includes(BrowserName.Edge))?.browserId!)
    ) {
      return ControlState.Hidden;
    }
    if (
      controlName == 'firefoxAppId' &&
      !this.landingPage?.browserIds.includes(this.cachedService.brosersList?.find((x) => x.browserName.toLowerCase().includes(BrowserName.Firefox))?.browserId!)
    ) {
      return ControlState.Hidden;
    }
    let control = companyData?.constrols.find((x) => x.name == controlName);
    if (control && control.state) {
      return control.state;
    }
    return ControlState.Hidden;
  }
}

export const autoFileResolver = (controlData: IDynamicDataControl, page: ILandingPage, cacheDataService: CacheDataService) => {
  try {
    switch (controlData.autoFillFrom) {
      case ControlNameType.Company:
        return cacheDataService.companyList?.find((x) => x.companyId == page.companyId || 0)?.companyName || '';

      case ControlNameType.ExtenstionName:
        return page.extensionName || '';

      case ControlNameType.SupportMail:
        return page.supportEmail || '';
      case ControlNameType.BrowserName:
        console.log(controlData);
        return cacheDataService.brosersList
          ?.filter((x) => page.browserIds.includes(x.browserId))
          .map((x) => x.browserName)
          .join(',');
      case ControlNameType.ExtensionType:
        return page.extensionType || '';
      default:
        return controlData.value || '';
    }
  } catch (e) {
    console.error(e);
    return controlData.value;
  }
};
export const replaceBeforeResolver = (controls: IDynamicDataControl[], staticPage: ILandingPage) => {
  controls?.forEach((element) => {
    if (element.replaceBeforeSave) {
      element.replaceBeforeSave.forEach((control) => {
        switch (control) {
          case ControlNameType.Domain:
            (staticPage as any)[element.name] = (staticPage as any)[element.name].replace('{Domain}', staticPage.domain);
            break;
          case ControlNameType.ExtenstionName:
            (staticPage as any)[element.name] = (staticPage as any)[element.name].replace('{$extenstionName}', staticPage.extensionName);
            break;
        }
      });
    }
  });
};
