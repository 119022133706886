import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSelectSearchModule } from 'mat-select-search';
import { MaterailModule } from '../../../materail/materail.module';

import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ControlNameType, CompanyType, ControlState, DistributorType, PageType, TopicsType } from '../../../constants/enums';
import { HttpService } from '../../../services/http.service';

import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ColorPickerModule } from 'ngx-color-picker';
import { combineLatest, map } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { CacheDataService } from '../../../services/cache-data.service';
import { LoaderService } from '../../../services/loader.service';
import { NotificationService } from '../../../services/notification.service';
import { ICompany, IDistributor, IPageType, ITemplate } from '../../../types/auth-response';
import { FileUploadComponent } from '../../shared/file-upload/file-upload.component';
import { ScriptsInputComponent } from '../../shared/scripts-input/scripts-input.component';
import { SelectBrowserComponent } from '../../shared/select-browser/select-browser.component';
import { TemplatePreviewComponent } from '../../shared/template-preview/template-preview.component';
import { LandingPageValidiator, autoFileResolver, replaceBeforeResolver } from './landing-page-common';
import { ILandingPage } from '../../../types/landing-page';
import { LoadingPlaceholderComponent } from '../../shared/loading-placeholder/loading-placeholder.component';
import { IScript } from '../../../types/static-page';
import { scriptTypes } from '../../shared/script-types';
import { scriptRegex, noscriptRegex } from '../../shared/regex-definitions';

@Component({
  selector: 'app-landing-page-form',
  templateUrl: './landing-page-form.component.html',
  imports: [
    CommonModule,
    MaterailModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectSearchModule,
    SelectBrowserComponent,
    FileUploadComponent,
    ScriptsInputComponent,
    ColorPickerModule,
    LoadingPlaceholderComponent,
    TemplatePreviewComponent,
  ],
  standalone: true,
  styles: [],
})
export class LandingPageFormComponent {
  color = 'red';  
  router = inject(Router);
  route = inject(ActivatedRoute);
  httpService = inject(HttpService);
  cacheDataService = inject(CacheDataService);
  formBuilder = inject(FormBuilder);
  loader = inject(LoaderService);
  dialog = inject(MatDialog);
  overlay = inject(Overlay);
  notificationService = inject(NotificationService);
  pageId: string | null = null;
  pageTypes: IPageType[] = [];   
  PageTypeEnum = PageType;
  templateTypes: ITemplate[] = [];
  distributors: IDistributor[] = [];
  companies: ICompany[] = [];
  scriptTypes: string[] = scriptTypes;
  templatesLoading = false;
  editMode = false;
  isScriptOpen=false;
  pageForm = this.formBuilder.group({
    pageTypeId: [0],
    templateId: [0],
    browserIds: [[0]],
    distributerId: [0],
    companyId: [0],
    distributionSubDomain: [''],
    chromeAppId: [''],
    edgeAppId: [''],
    firefoxAppId: [''],
    browserName: [''],
    productName: [''],
    pageName: [''],
    domain: [''],
    supportEmail: [''],
    extensionName: [''],
    extensionType: [''],
    appId: [0],
    icon: [''],
    logo: [''],
    buttonColor: [''],
    sectionTwoImage: [''],
    sectionThreeColor: [''],
    sectionFourColor: [''],
    sectionOneArrowImage: [''],
    p1: [''],
    p2: [''],
    d1: [''],
    d2: [''],
    d3: [''],
    d4: [''],
    d5: [''],
    d6: [''],
    scripts: this.formBuilder.array([]),
  });
  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(map((results) => ({ params: results[0], query: results[1] })))
      .subscribe((results) => {
        this.pageId = results.params['id'] || null;
        const editmode = results.query['editmode'] || 'true';
        console.log('nginit', this.pageId, editmode);
        if (this.pageId) {
          this.initEdit(this.pageId, editmode == 'true');
        } else {
          this.initAdd();
        }
      });
  }

  initAdd() {
    this.editMode = true;
    this.loader.show();
    this.httpService.getPageTypeByTopic(this.cacheDataService.topicHaskey[TopicsType.ExtensionsLandingPages]).subscribe((result) => {
      this.pageTypes = result.data;      
      this.loader.hide();
    });
    this.addScriptRow({ edit: true }, 0);
    this.setControlState(true);
    this.handleValueChanges();
  }
  initEdit(id: string, editMode: boolean) {
    this.loader.show();
    const pageTypes = this.httpService.getPageTypeByTopic(this.cacheDataService.topicHaskey[TopicsType.ExtensionsLandingPages]);
    const pageDetail = this.httpService.getExtensionLandingPage(+id);
    forkJoin([pageTypes, pageDetail]).subscribe({
      next: (results) => {
        this.pageTypes = results[0].data;
        const formValues = results[1];
        for (let i = 0; i < formValues.scripts!?.length; i++) {
          this.addScriptRow({ edit: false }, i);
        }
        this.pageForm.patchValue(formValues);
        this.pageForm.controls.pageTypeId.disable();       
        this.loader.hide();
        this.editMode = editMode;
        this.addScriptRow({ edit: true }, formValues.scripts!?.length);
        this.bindTemplateType(this.pageForm.controls.pageTypeId.value || 0);
        this.setControlState(false);
        setTimeout(() => {
          this.handleValueChanges();
        });

        if (!editMode) {
          setTimeout(() => {
             this.pageForm.disable({emitEvent:false})
          }, 1000);
        }
      },
      complete: () => {
        this.loader.hide();
      },
    });
  }
  get pageTypeHaskey() {
    let hash: any = {};
    this.pageTypes.forEach((e) => {
      hash[e.pageTypeId] = e.pageTypeName.toLowerCase().trim();
    });
    return hash;
  }

  showControl(controlName: string) {
    const pageTypeId = +(this.pageForm.controls.pageTypeId.value || 0);
    const distributerId = +(this.pageForm.controls.distributerId.value || 0);
    const companyId = +(this.pageForm.controls.companyId.value || 0);
    const landingPageValidiator = new LandingPageValidiator(
      this.pageTypeHaskey,
      this.cacheDataService,
      this.pageForm.getRawValue() as ILandingPage,
      this.pageTypeName
    );
    return landingPageValidiator.getControlState(pageTypeId, distributerId, companyId, controlName) != ControlState.Hidden;
  }
  get isUninstallPage() {
    return this.pageTypeName == PageType.Uninstall;
  }
  get isDistributionPage() {
    return this.pageTypeName == PageType.Distribution;
  }
  get pageTypeName() {
    return this.pageTypeHaskey[this.pageForm.value.pageTypeId!] as PageType;
  }
  async iconUploaded(url: string) {
    this.pageForm.controls.icon.setValue(url);
  }
  async logoUpload(url: string) {
    this.pageForm.controls.logo.setValue(url);
  }

  addScriptRow(value?: any, index?: any) {
    if (value) {
      let a = this.formBuilder.group({
        scriptName: value.scriptName || '',
        scriptType: value.scriptType || '',
        scriptContent: value.scriptContent || '',
        edit: value.edit || false,
      });
      if (!value.edit) {
        a.disable();
      }
      this.scriptsControl.push(a);
    } else {
      if (this.scriptsControl.length > 0) {
        let row = this.scriptsControl[index];
        let x = row.value;
        if (!this.validiateScript(x)) {
          return;
        }
        row.patchValue({
          edit: false,
        });
        row.disable();
      }
      let a = this.formBuilder.group({
        scriptName: '',
        scriptType: '',
        scriptContent: '',
        edit: true,
      });
      this.scriptsControl.push(a);
    }
  }
  removeScript(i: number) {
    this.scriptsControl.splice(i, 1);
  }
  editScript(i: number) {
    let row = this.scriptsControl[i];
    row.patchValue({
      edit: true,
    });
    row.enable();
    row.markAsPristine();
  }
  updateScript(i: number) {
    let row = this.scriptsControl[i];
    let x = row.value;
    if (!this.validiateScript(x)) {
      return;
    }
    row.patchValue({
      edit: false,
    });
    row.disable();
  }
  validiateScript(script: IScript) {
    let errors: string[] = [];
    if (!script.scriptName) {
      errors.push('Enter script name.');
    }
    if (!script.scriptType) {
      errors.push('Enter script type.');
    }
    if (!script.scriptContent) {
      errors.push('Enter script content.');
    }
    if(script.scriptType == 'Begin of Body'){
      if(!noscriptRegex.test(script.scriptContent)){
        errors.push('Not valid script content was added. For this script type \'noscript\' tag is required.');
      }
    }
    else {
      if(!scriptRegex.test(script.scriptContent)) {
        errors.push('Not valid script content was added. For this script type \'script\' tag is required.');
      }
    }   

    if (errors.length) {
      this.notificationService.showErros(errors);
      return false;
    }
    return true;
  }
  isScriptUnsaved() {
    console.log(this.scriptsControl);
    let unsaved = false;
    this.scriptsControl.forEach((value, i) => {
      if (i != this.scriptsControl.length - 1 && value.status != 'DISABLED' && value.dirty) {
        unsaved = true;
      }
      if (i == this.scriptsControl.length - 1 && (value.value.scriptName || value.value.scriptType || value.value.scriptContent)) {
        unsaved = true;
      }
    });
    if (!unsaved) {
      this.scriptsControl.forEach((value, i) => {
        if (i != this.scriptsControl.length - 1) {
          value.patchValue({
            edit: false,
          });
          value.disable();
        }
      });
    }
    return unsaved;
  }
  add() {
    let formValue = this.pageForm.getRawValue() as ILandingPage;
    formValue.pageName = formValue.extensionName + ' ' + this.pageTypes.find((x) => x.pageTypeId == formValue.pageTypeId)?.pageTypeName;
    //formValue.productName = formValue.pageName;
    formValue.scripts = this.scriptsControl.map((x) => x.value).filter((x) => x.scriptName && x.scriptContent && x.scriptType);
    if (!formValue.distributerId) {
      formValue.distributerId = +this.cacheDataService.distributorList.find((x) => x.distributerName.toLocaleLowerCase() == 'general')!.distributerId;
    }
    if (!formValue.companyId) {
      formValue.companyId = +this.cacheDataService.companyList.find((x) => x.companyName.toLocaleLowerCase() == 'general')!.companyId;
    }
    const landingPageValidiator = new LandingPageValidiator(this.pageTypeHaskey, this.cacheDataService, formValue, this.pageTypeName);
    const result = landingPageValidiator.isFormValid();
    if (!result.isValid) {
      this.notificationService.showErros(result.errors);
      return;
    }
    if (this.isScriptUnsaved()) {
      return this.notificationService.showErros(['You have unsaved scripts']);
    }
    replaceBeforeResolver(this.controlsData, formValue);
    formValue = this.removeBeforeSave(formValue);
    formValue.browserIds = formValue.browserIds.filter(x=>x);
    this.loader.show();
    this.httpService.addLandingPage(formValue).subscribe((result) => {
      this.loader.hide();
      if (result && result.id) {
        this.notificationService.success('Page saved sucessfully');
        this.router.navigateByUrl('extension-landings/' + result.id + '?editmode=false');
      }
    });
  }
  edit() {
    this.editMode = true;
    this.pageForm.enable({ emitEvent: false });
    this.scriptsControl?.forEach((value, i) => {
      if (i != this.scriptsControl.length - 1) value.disable();
    });
    setTimeout(() => {
      this.pageForm.controls.pageTypeId.disable({ emitEvent: false });
      this.setControlState(false);
    });
  }
  update() {
    let formValue: any = this.pageForm.getRawValue();
    formValue.pageName = formValue.extensionName + ' ' + this.pageTypes.find((x) => x.pageTypeId == formValue.pageTypeId)?.pageTypeName;
    //formValue.productName = formValue.pageName;
    formValue.scripts = this.scriptsControl.map((x) => x.value).filter((x) => x.scriptName && x.scriptContent && x.scriptType);
    const landingPageValidiator = new LandingPageValidiator(this.pageTypeHaskey, this.cacheDataService, formValue, this.pageTypeName);
    const result = landingPageValidiator.isFormValid();
    if (!result.isValid) {
      this.notificationService.showErros(result.errors);
      return;
    }
    if (this.isScriptUnsaved()) {
      return this.notificationService.showErros(['You have unsaved scripts']);
    }
    formValue = this.removeBeforeSave(formValue);
    formValue.browserIds = formValue.browserIds.filter((x:any)=>x);
    this.loader.show();
    this.httpService.updateLandingPagee(+this.pageId!, formValue).subscribe((result) => {
      this.loader.hide();
      this.notificationService.success('Page updated sucessfully');
      this.editMode = false;
      this.pageForm.disable({ emitEvent: false });
    });
  }
  removeBeforeSave(formValue: any) {
    for (let key in formValue) {
      if (['pageTypeId', 'templateId', 'browserIds', 'distributerId', 'companyId', 'pageName', 'icon', 'scripts'].includes(key)) continue;
      if (!this.showControl(key)) {
        console.log(key);
        console.log(key, typeof formValue[key]);
        if (typeof formValue[key] == 'string') {
          formValue[key] = '';
        } else if (typeof formValue[key] == 'number') {
          formValue[key] = 0;
        } else {
          formValue[key] = null;
        }
      }
    }
    return formValue;
  }
  publish() {
    this.loader.show();
    this.httpService.publishLandingPage(+this.pageForm.value.templateId!, +this.pageId!).subscribe({
      next: (result) => {
        if (result.isPublishedSucceeded) window.open(result.publishedUrl);
        this.notificationService.success('Page published sucessfully');
        this.router.navigateByUrl('extension-landings');
        this.loader.hide();
      },
    });
  }
  previewPage() {
    let template = this.templateTypes.find((x) => x.templateId == this.pageForm.controls.templateId.value!);
    let scrollStrategy = this.overlay.scrollStrategies.reposition();
    this.dialog.open(TemplatePreviewComponent, {
      width: '80vw',
      height: '80vh',
      panelClass: 'template-preview',
      scrollStrategy: scrollStrategy,
      data: {
        template: template,
      },
    });
  }
  get scriptsControl() {
    return (this.pageForm.get('scripts') as FormArray).controls;
  }

  bindTemplateType(pageTypeId?: number) {
    this.templatesLoading = true;
    this.httpService.getTemplateByPagetype(pageTypeId).subscribe({
      next: (result: any) => {
        this.templateTypes = result.data;
        this.templatesLoading = false;
      },
    });
  }
  handleValueChanges() {
    console.log('handleControlStateAndValue');
    this.pageForm.controls.pageTypeId.valueChanges.subscribe((value) => {
      console.log('pageTypeId', value);
      this.pageForm.patchValue({
        templateId: 0,
      });
      this.bindTemplateType(value!);
      this.setControlState(true);
    });

    this.pageForm.controls.supportEmail.valueChanges.subscribe((value) => {
      this.controlsData
        .filter((x) => x.autoFillFrom == ControlNameType.SupportMail)
        .forEach((control) => {
          this.pageForm.get(control.name)?.setValue(autoFileResolver(control, this.pageForm.getRawValue() as ILandingPage, this.cacheDataService));
        });
    });

    this.pageForm.controls.extensionName.valueChanges.subscribe((value) => {
      this.controlsData
        .filter((x) => x.autoFillFrom == ControlNameType.ExtenstionName)
        .forEach((control) => {
          this.pageForm.get(control.name)?.setValue(autoFileResolver(control, this.pageForm.getRawValue() as ILandingPage, this.cacheDataService));
        });
    });

    this.pageForm.controls.browserIds.valueChanges.subscribe((value) => {
      console.log('browser change');
      this.controlsData
        .filter((x) => x.autoFillFrom == ControlNameType.BrowserName)
        .forEach((control) => {
          this.pageForm.get(control.name)?.setValue(autoFileResolver(control, this.pageForm.getRawValue() as ILandingPage, this.cacheDataService));
        });
    });
    this.pageForm.controls.extensionType.valueChanges.subscribe((value) => {
      this.controlsData
        .filter((x) => x.autoFillFrom == ControlNameType.ExtensionType)
        .forEach((control) => {
          this.pageForm.get(control.name)?.setValue(autoFileResolver(control, this.pageForm.getRawValue() as ILandingPage, this.cacheDataService));
        });
    });
  }

  get controlsData() {
    let pageTypeKey: string = this.pageTypeHaskey[this.pageForm.controls.pageTypeId.value!];
    if (!pageTypeKey) return [];
    let distributerIdKey: string = this.cacheDataService.distributorHaskey[this.pageForm.controls.distributerId.value!];
    let companyKey: string = this.cacheDataService.comapnyHaskey[this.pageForm.controls.companyId.value!];
    console.log(pageTypeKey, distributerIdKey, companyKey, this.cacheDataService.extensionLandingPageControlConfig);
    let pageTypeData = this.cacheDataService.extensionLandingPageControlConfig.find((x) => x.pageTypeId == pageTypeKey);
    let distributorsData = pageTypeData?.distributors.find((x) => x.distributorId == distributerIdKey);
    if (!distributorsData) {
      distributorsData = pageTypeData?.distributors.find((x) => x.distributorId == DistributorType.Any);
    }
    let companyData = distributorsData?.companies?.find((x) => x.companyId == companyKey);
    if (!companyData) {
      companyData = distributorsData?.companies?.find((x) => x.companyId == CompanyType.Any);
    }
    return companyData?.constrols || [];
  }
  private setControlState(updateValue: boolean) {
    console.log('this.controlsData', this.controlsData);
    this.controlsData?.forEach((element) => {
      let formControl = this.pageForm.get(element.name);
      if (updateValue) {
        formControl?.setValue(autoFileResolver(element, this.pageForm.getRawValue() as ILandingPage, this.cacheDataService));
      }
      switch (element.state) {
        case ControlState.Hidden:
          formControl?.disable();
          break;
        case ControlState.Enable:
          formControl?.enable();
          break;
        case ControlState.Disable:
          formControl?.disable();
          break;
      }
    });

    if(this.pageId){
      this.pageForm.controls.extensionType.disable();
    }
  }
}
