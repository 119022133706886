import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterailModule } from '../../materail/materail.module';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounce, debounceTime } from 'rxjs';

@Component({
  selector: 'app-search-filter',
  standalone: true,
  imports: [CommonModule, MaterailModule, ReactiveFormsModule],
  template: `
    <div class="!w-80">
      <mat-form-field class="w-full" subscriptSizing="dynamic">
        <input matInput type="text" [placeholder]="placeholder" [formControl]="search" [autocomplete]="false" />
        <mat-icon matIconSuffix><img src="/assets/icons/Icon-search.svg"></mat-icon>
      </mat-form-field>
    </div>
  `,
  styles: ``,
})
export class SearchFilterComponent {
  search = new FormControl('');
  @Input() placeholder="search by publish url";
  @Output() change=new EventEmitter<string>();
  ngOnInit() {
    this.search.valueChanges.pipe(debounceTime(250)).subscribe((value) => {
      console.log(value);
      this.change.emit(value || '')
    });
  }
}
