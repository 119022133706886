
<div class="browser-type-container">
  @for(browser of browsers; track $index){
  <div class="browser-type" (click)="disabled?null:selected(browser.browserId)">
    <div>
      <img class="browser-img" [src]="browser.browserStoreLogo" />
      <span>{{ browser.browserName }}</span>
      <span
        [ngClass]="{ 'check-circle': isSelected(browser.browserId) }"
      ></span>
    </div>
  </div>
  }
</div>
