<div class="page-header">
  <h2>Extension Landing Pages</h2>
</div>
<form [formGroup]="pageForm">
  <div class="data-card" [ngClass]="{ 'opacity-40': !editMode }">
    <div class="flex w-full">
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Type of Landing Page <span class="required">*</span></mat-label>
          <mat-form-field subscriptSizing="dynamic">
            <mat-select formControlName="pageTypeId">
              @for (item of pageTypes; track $index) {
              <mat-option [value]="item.pageTypeId">
                {{ item.pageTypeName }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      @if(showControl('distributionSubDomain')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>SubDomain <span class="required">*</span></mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="distributionSubDomain" />
          </mat-form-field>
        </div>
      </div>
    }
      <div class="w-4/12">
        <app-file-upload
          [label]="'Icon image'"
          [imageUrl]="pageForm.controls.icon.value!"
          (imageUpload)="pageForm.controls.icon.setValue($event)"
        ></app-file-upload>
      </div>
    </div>
    <div class="flex w-full">
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Select Browser <span class="required">*</span></mat-label>
          <app-select-browser
            [multiple]="true"
            [values]="pageForm.controls.browserIds.value!"
            (changed)="pageForm.controls.browserIds.setValue($event)"
          ></app-select-browser>
        </div>
      </div>
      @if(templatesLoading){
      <app-loading-placeholder></app-loading-placeholder>
      } @else if(pageForm.controls.pageTypeId.value){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Select Template <span class="required">*</span></mat-label>
          <mat-form-field subscriptSizing="dynamic">
            <mat-select formControlName="templateId">
              @for (item of templateTypes; track $index) {
              <mat-option [value]="item.templateId">
                {{ item.templateName }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        @if(pageForm.controls.templateId.value){
        <span class="small-link mt-1" (click)="previewPage()">See Preview</span>
        }
      </div>
      }
    </div>
  </div>
  @if(pageForm.controls.pageTypeId.value){
  <div class="data-card" [ngClass]="{ 'opacity-40': !editMode }">
    <div class="flex flex-wrap w-full">
      @if(showControl('chromeAppId')){
      <div class="w-full">
        <div class="form-field">
          <mat-label>Chrome app store extension id </mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="chromeAppId" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('edgeAppId')){
      <div class="w-full">
        <div class="form-field">
          <mat-label>Edge app store extension id </mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="edgeAppId" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('firefoxAppId')){
      <div class="w-full">
        <div class="form-field">
          <mat-label>Firefox AddOns extension url </mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="firefoxAppId" />
          </mat-form-field>
        </div>
      </div>
      }
    </div>
    <div class="flex flex-wrap w-full">

      @if(showControl('extensionName')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Extension Name <span class="required">*</span></mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="extensionName" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('productName')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Product Name <span class="required">*</span></mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="productName" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('domain')){
        <div class="w-4/12">
          <div class="form-field">
            <mat-label>Domain <span class="required">*</span></mat-label>
            <mat-form-field>
              <input matInput type="text" formControlName="domain" />
            </mat-form-field>
          </div>
        </div>
        } 
      
        @if(showControl('supportEmail')){
          <div class="w-4/12">
            <div class="form-field">
              <mat-label>Support Mail <span class="required">*</span></mat-label>
              <mat-form-field>
                <input type="text" matInput formControlName="supportEmail" />
              </mat-form-field>
            </div>
          </div>
          }
      @if(showControl('appId')){
        <div class="w-4/12">
          <div class="form-field">
            <mat-label>App Web Id <span class="required">*</span></mat-label>
            <mat-form-field>
              <input matInput type="number" formControlName="appId" />
            </mat-form-field>
          </div>
        </div>
        } 
      @if(showControl('logo')){
        <div class="w-4/12">
          <app-file-upload
            [label]="'Logo image'"
            [imageUrl]="pageForm.controls.logo.value!"
            (imageUpload)="pageForm.controls.logo.setValue($event)"
          ></app-file-upload>
        </div>
        }
      @if(showControl('sectionOneArrowImage')){
        <div class="w-4/12">
          <app-file-upload
            [label]="'SectionOneArrow image'"
            [imageUrl]="pageForm.controls.sectionOneArrowImage.value!"
            (imageUpload)="pageForm.controls.sectionOneArrowImage.setValue($event)"
          ></app-file-upload>
        </div>
        }
      @if(showControl('browserName')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Browser Name <span class="required">*</span></mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="browserName" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('extensionType')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Extension Type <span class="required">*</span></mat-label>
          <mat-form-field subscriptSizing="dynamic">
            <mat-select formControlName="extensionType">
              <mat-option [value]="'newtab'"> newtab </mat-option>
              <mat-option [value]="'default'"> default </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('buttonColor')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Section 1 - Button Color <span class="required">*</span></mat-label>
          <mat-form-field>
            <input
              matInput
              [readonly]="true"
              [cpOKButton]="true"
              [colorPicker]="pageForm.controls.buttonColor.value!"
              (colorPickerSelect)="pageForm.controls.buttonColor.setValue($event)"
              [style.background]="pageForm.controls.buttonColor.value"
            />
          </mat-form-field>

          <div></div>
        </div>
      </div>
      } @if(showControl('sectionTwoImage')){
      <div class="w-4/12">
        <app-file-upload
          [label]="'Section 2 - Background Image'"
          [imageUrl]="pageForm.controls.sectionTwoImage.value!"
          (imageUpload)="pageForm.controls.sectionTwoImage.setValue($event)"
        ></app-file-upload>
      </div>
      } @if(showControl('p1')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Section 3 - P1 <span class="required">*</span></mat-label>
          <mat-form-field>
            <textarea matInput type="text" formControlName="p1" rows="2"></textarea>
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('p2')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Section 3 - P2 <span class="required">*</span></mat-label>
          <mat-form-field>
            <textarea matInput type="text" formControlName="p2" rows="2"></textarea>
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('sectionThreeColor')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Section 3 - Bg Color <span class="required">*</span></mat-label>
          <mat-form-field>
            <input
              matInput
              [readonly]="true"
              [cpOKButton]="true"
              [colorPicker]="pageForm.controls.sectionThreeColor.value!"
              (colorPickerSelect)="pageForm.controls.sectionThreeColor.setValue($event)"
              [style.background]="pageForm.controls.sectionThreeColor.value"
            />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('sectionFourColor')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Section 4 - Bg Color <span class="required">*</span></mat-label>
          <mat-form-field>
            <input
              matInput
              [readonly]="true"
              [cpOKButton]="true"
              [colorPicker]="pageForm.controls.sectionFourColor.value!"
              (colorPickerSelect)="pageForm.controls.sectionFourColor.setValue($event)"
              [style.background]="pageForm.controls.sectionFourColor.value"
            />
          </mat-form-field>
        </div>
      </div>
      }
    </div>

    <div class="flex flex-wrap w-full">
      @if(showControl('d1')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>$1</mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="d1" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('d2')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>$2</mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="d2" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('d3')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>$3</mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="d3" />
          </mat-form-field>
        </div>
      </div>
      }
    </div>

    <div class="flex flex-wrap w-full">
      @if(showControl('d4')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>$4</mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="d4" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('d5')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>$5</mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="d5" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('d6')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>$6</mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="d6" />
          </mat-form-field>
        </div>
      </div>
      }
    </div>
  </div>
}
  <div class="data-card" formArrayName="scripts" [ngClass]="{ 'opacity-40': !editMode }">
    <div class="cursor-pointer relative" (click)="isScriptOpen = !isScriptOpen">
      <span class="font-bold cursor-pointer">Scripts</span>
      <mat-icon *ngIf="!isScriptOpen" matSuffix class="absolute top-2 left-14"><img src="/assets/icons/expand.png" /></mat-icon>
      <mat-icon *ngIf="isScriptOpen" matSuffix class="absolute top-2 left-14"><img src="/assets/icons/collapse.png" /></mat-icon>
    </div>
    @if(isScriptOpen){
    <div class="hr-line"></div>
    @for (script of scriptsControl; track script;) {
    <div class="flex w-full" [formGroupName]="$index">
      <div class="w-2/12">
        <div class="form-field">
          <mat-label>Script Name <span class="required">*</span></mat-label>
          <mat-form-field>
            <input type="text" matInput formControlName="scriptName" />
          </mat-form-field>
        </div>
      </div>
      <div class="w-2/12">
        <div class="form-field">
          <mat-label>Type of script <span class="required">*</span></mat-label>          
          <mat-form-field subscriptSizing="dynamic">
            <mat-select formControlName="scriptType">
              @for (item of scriptTypes; track $index) {
              <mat-option [value]="item">
                {{ item }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="w-5/12">
        <div class="form-field">
          <mat-label>Script Content <span class="required">*</span></mat-label>
          <mat-form-field>
            <textarea type="text" matInput rows="4" formControlName="scriptContent"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="w-3/12">
        @if(scriptsControl.length==$index+1){
        <div class="mutile-row-input-action">
          <div class="flex">
            <mat-icon matPrefix><img src="../../../../assets/icons/Icon-plus-circle.svg" /></mat-icon>
            <button mat-button color="primary" (click)="addScriptRow(null, $index)" [disabled]="!editMode">Add Script</button>
          </div>
        </div>
        } @else{
        <div class="mutile-row-input-action">
          @if(scriptsControl[$index].value.edit){
          <div class="flex">
            <mat-icon matPrefix><img src="../../../../assets/icons/edit.svg" /></mat-icon>
            <button mat-button color="primary" (click)="updateScript($index)" [disabled]="!editMode">Update</button>
          </div>
          } @else{
          <div class="flex">
            <mat-icon matPrefix><img src="../../../../assets/icons/edit.svg" /></mat-icon>
            <button mat-button color="primary" (click)="editScript($index)" [disabled]="!editMode">Edit</button>
          </div>
          <div class="flex">
            <mat-icon matPrefix><img src="../../../../assets/icons/close-circle.svg" /></mat-icon>

            <button mat-button color="warn" (click)="removeScript($index)" [disabled]="!editMode">Delete Script</button>
          </div>
          }
        </div>
        }
      </div>
    </div>
    }}
  </div>
  @if(!pageId){
  <div class="flex w-full justify-center">
    <button mat-raised-button class="main-btn w-48" (click)="add()">Save</button>
  </div>
  }@else{
  <div class="flex w-full justify-center gap-8">
    @if(editMode){
    <button mat-raised-button class="main-btn outlined-btn w-48" (click)="update()">Update</button>
    } @else {
    <button mat-raised-button class="main-btn outlined-btn w-48" (click)="edit()">Edit</button>
    <button mat-stroked-button class="main-btn w-48" (click)="publish()">Publish</button>
    }
  </div>
  }
</form>
