<div class="page-header">
  <h2>Extension Static Pages</h2>
</div>
<form [formGroup]="pageForm">
  <div class="data-card" [ngClass]="{ 'opacity-40': !editMode }">
    <div class="flex w-full">
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Type of Static Page <span class="required">*</span></mat-label>
          <mat-form-field>
            <mat-select formControlName="pageTypeId">
              @for (item of pageTypes; track $index) {
              <mat-option [value]="item.pageTypeId">
                {{ item.pageTypeName }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="w-4/12">
        <app-file-upload [label]="'Icon image'" [imageUrl]="pageForm.controls.icon.value!" (imageUpload)="iconUploaded($event)"></app-file-upload>
      </div>
    </div>
    @if(templatesLoading){
    <app-loading-placeholder></app-loading-placeholder>
    } @else if(pageForm.controls.pageTypeId.value){
    <div class="flex w-full">
      <!-- @if(showControl('browserId')){
      <div class="w-6/12">
        <div class="form-field">
          <mat-label>Select Browser <span *ngIf="isRequired('browserId')" class="required">*</span></mat-label>
          <app-select-browser [value]="pageForm.controls.browserId.value" (changed)="browserSelected($event)"></app-select-browser>
        </div>
      </div>
      } -->
      <div [ngClass]="{ 'w-3/12': isUninstallPage, 'w-4/12': !isUninstallPage }">
        <div class="form-field">
          <mat-label>Select Template <span class="required">*</span></mat-label>
          <mat-form-field subscriptSizing="dynamic">
            <mat-select formControlName="templateId">
              @for (item of templateTypes; track $index) {
              <mat-option [value]="item.templateId">
                {{ item.templateName }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        @if(pageForm.controls.templateId.value){
        <span class="small-link mt-2" (click)="previewPage()">See Preview</span>
        }
      </div>
      @if(showControl('distributerId')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Select Distributor <span class="required">*</span></mat-label>
          <mat-form-field>
            <mat-select formControlName="distributerId">
              @for (item of distributors; track $index) {
              <mat-option [value]="item.distributerId">
                {{ item.distributerName }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('companyId')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Company <span class="required">*</span></mat-label>
          <mat-form-field>
            <mat-select formControlName="companyId">
              @for (item of companies; track $index) {
              <mat-option [value]="item.companyId">
                {{ item.companyName }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      }
    </div>
    }
  </div>
  @if(pageForm.controls.pageTypeId.value && (pageForm.controls.distributerId.value || isDistributerHidden)){
  <div class="data-card" [ngClass]="{ 'opacity-40': !editMode }">
    <div class="flex flex-wrap w-full">
      @if(showControl('logo')){
      <div class="w-4/12">
        <app-file-upload [label]="'Logo image'" [imageUrl]="pageForm.controls.logo.value!" (imageUpload)="logoUpload($event)"></app-file-upload>
      </div>
      }@if(showControl('extensionName')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Extension Name <span *ngIf="isRequired('extensionName')" class="required">*</span></mat-label>
          <mat-form-field>
            <input type="text" matInput formControlName="extensionName" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('domain')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Domain <span *ngIf="isRequired('domain')" class="required">*</span></mat-label>
          <mat-form-field>
            <input type="text" matInput formControlName="domain" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('supportEmail')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Support Mail <span *ngIf="isRequired('supportEmail')" class="required">*</span></mat-label>
          <mat-form-field>
            <input type="text" matInput formControlName="supportEmail" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('appId')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>App Web Id <span class="required">*</span></mat-label>
          <mat-form-field>
            <input matInput type="number" formControlName="appId" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('pixelUrl')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Pixel Url <span class="required">*</span></mat-label>
          <mat-form-field>
            <input matInput type="text" formControlName="pixelUrl" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('headlineBackgroundColor')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>Headline BackgroundColor <span class="required">*</span></mat-label>
          <mat-form-field>
            <input
              matInput
              [readonly]="true"
              [cpOKButton]="true"
              [colorPicker]="pageForm.controls.headlineBackgroundColor.value!"
              (colorPickerSelect)="pageForm.controls.headlineBackgroundColor.setValue($event)"
              [style.background]="pageForm.controls.headlineBackgroundColor.value"
            />
          </mat-form-field>

          <div></div>
        </div>
      </div>
      } @if(showControl('p1')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>P1 <span *ngIf="isRequired('p1')" class="required">*</span></mat-label>
          <mat-form-field>
            <textarea type="text" matInput formControlName="p1" rows="2"></textarea>
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('p2')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>P2 <span *ngIf="isRequired('p2')" class="required">*</span></mat-label>
          <mat-form-field>
            <textarea type="text" matInput formControlName="p2" rows="2"></textarea>
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('p3')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>P3 <span *ngIf="isRequired('p3')" class="required">*</span></mat-label>
          <mat-form-field>
            <textarea type="text" matInput formControlName="p3" rows="2"></textarea>
          </mat-form-field>
        </div>
      </div>
      }
    </div>
    <div class="flex w-full">
      @if(showControl('d1')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>$1 <span *ngIf="isRequired('d1')" class="required">*</span></mat-label>
          <mat-form-field>
            <input type="text" matInput formControlName="d1" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('d2')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>$2 <span *ngIf="isRequired('d2')" class="required">*</span></mat-label>
          <mat-form-field>
            <input type="text" matInput formControlName="d2" />
          </mat-form-field>
        </div>
      </div>
      } @if(showControl('d3')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>$3 <span *ngIf="isRequired('d3')" class="required">*</span></mat-label>
          <mat-form-field>
            <input type="text" matInput formControlName="d3" />
          </mat-form-field>
        </div>
      </div>
      }
    </div>
    <div class="flex w-full">
      @if(showControl('d4')){
      <div class="w-4/12">
        <div class="form-field">
          <mat-label>$4 <span *ngIf="isRequired('d4')" class="required">*</span></mat-label>
          <mat-form-field>
            <input type="text" matInput formControlName="d4" />
          </mat-form-field>
        </div>
      </div>
      }
    </div>
  </div>
  }
  <div class="data-card" formArrayName="scripts" [ngClass]="{ 'opacity-40': !editMode }">
    <div class="cursor-pointer relative" (click)="isScriptOpen = !isScriptOpen">
      <span class="font-bold cursor-pointer">Scripts</span>
      <mat-icon *ngIf="!isScriptOpen" matSuffix class="absolute top-2 left-14"><img src="/assets/icons/expand.png" /></mat-icon>
      <mat-icon *ngIf="isScriptOpen" matSuffix class="absolute top-2 left-14"><img src="/assets/icons/collapse.png" /></mat-icon>
    </div>
    @if(isScriptOpen){
    <div class="hr-line"></div>
    @for (script of scriptsControl; track script;) {
    <div class="flex w-full" [formGroupName]="$index">
      <div class="w-2/12">
        <div class="form-field">
          <mat-label>Script Name <span class="required">*</span></mat-label>
          <mat-form-field>
            <input type="text" matInput formControlName="scriptName" />
          </mat-form-field>
        </div>
      </div>
      <div class="w-2/12">
        <div class="form-field">
          <mat-label>Type of script <span class="required">*</span></mat-label>          
          <mat-form-field subscriptSizing="dynamic">
            <mat-select formControlName="scriptType">
              @for (item of scriptTypes; track $index) {
              <mat-option [value]="item">
                {{ item }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="w-5/12">
        <div class="form-field">
          <mat-label>Script Content <span class="required">*</span></mat-label>
          <mat-form-field>
            <textarea type="text" matInput rows="4" formControlName="scriptContent"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="w-3/12">
        @if(scriptsControl.length==$index+1){
        <div class="mutile-row-input-action">
          <div class="flex">
            <mat-icon matPrefix><img src="../../../../assets/icons/Icon-plus-circle.svg" /></mat-icon>
            <button mat-button color="primary" (click)="addScriptRow(null, $index)" [disabled]="!editMode">Add Script</button>
          </div>
        </div>
        } @else{
        <div class="mutile-row-input-action">
          @if(scriptsControl[$index].value.edit){
          <div class="flex">
            <mat-icon matPrefix><img src="../../../../assets/icons/edit.svg" /></mat-icon>
            <button mat-button color="primary" (click)="updateScript($index)" [disabled]="!editMode">Update</button>
          </div>
          } @else{
          <div class="flex">
            <mat-icon matPrefix><img src="../../../../assets/icons/edit.svg" /></mat-icon>
            <button mat-button color="primary" (click)="editScript($index)" [disabled]="!editMode">Edit</button>
          </div>
          <div class="flex">
            <mat-icon matPrefix><img src="../../../../assets/icons/close-circle.svg" /></mat-icon>

            <button mat-button color="warn" (click)="removeScript($index)" [disabled]="!editMode">Delete Script</button>
          </div>
          }
        </div>
        }
      </div>
    </div>
    } }
  </div>
  @if(!pageId){
  <div class="flex w-full justify-center">
    <button mat-raised-button class="main-btn w-48" (click)="add()">Save</button>
  </div>
  }@else{
  <div class="flex w-full justify-center gap-8">
    @if(editMode){
    <button mat-raised-button class="main-btn outlined-btn w-48" (click)="update()">Update</button>
    }@else {
    <button mat-raised-button class="main-btn outlined-btn w-48" (click)="edit()">Edit</button>
    <button mat-stroked-button class="main-btn w-48" (click)="publish()">Publish</button>
    }
  </div>
  }
</form>
