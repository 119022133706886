<div class="page-header">
  <h2>Extension  Landing Pages</h2>
  <div class="filters">
    <app-search-filter (change)="searchChange($event)"></app-search-filter>
    <mat-form-field subscriptSizing="dynamic">
      <mat-select value="" (selectionChange)="publishFilter($event)">
        <mat-option value=""> All </mat-option>
        <mat-option value="1"> Published </mat-option>
        <mat-option value="2"> Unpublished </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button class="main-btn" (click)="addNewPage()">
      + Add New Page
    </button>
  </div>
</div>
<app-table [data]="filteredPageList" [total]="totalPages" [headers]="headers" (publish)="publish($event)" (edit)="edit($event)" (onSorting)="sortData($event)"/>
