import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loading = false;
  constructor(private _spinner: NgxSpinnerService) {}

  show() {
    this._spinner.show();
    this.loading = true;
  }
  hide(delay?: number) {
    if (delay) {
      setTimeout(() => {
        this._spinner.hide();
        this.loading = false;
      }, delay);
    } else {
      this._spinner.hide();
      this.loading = false;
    }
  }
  get isLoading() {
    return this.loading;
  }
}
