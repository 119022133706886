<div class="w-full h-full p-2">
  <div mat-dialog-title class="!flex !justify-end pt-2">
    <button mat-icon-button aria-label="close dialog" class="!bg-gray-500 !text-white !flex !justify-center !items-center" mat-dialog-close align="end">
      X
    </button>
  </div>
  <img [src]="data.template.templatePreviewImagePath" alt="Template preview image"  />
  @if(data.template.remarks){
  <div class="px-12 py-4">
    <label class="font-bold inline-block">Notes:</label>
    <pre>{{ data.template.remarks }}</pre>
  </div>
  }
</div>
