<div class="p-4">
  @if(data.type==DialogBoxType.error){
  <div mat-dialog-content>
    <h4 class="font-bold text-xl mb-4">Errors:</h4>
    <ul class="p-4 list-disc">
      @for (item of data.errors; track item) {
      <li class="text-red-600">{{ item }}</li>
      }
    </ul>
  </div>
  <div mat-dialog-actions>
    <div class="w-full flex gap-4 justify-end pr-4">
      <button class="edit-btn" (click)="ok()">close</button>
    </div>
  </div>
  }@else {
  <div mat-dialog-content>
    <p class="text-black">
      {{ data.message }}
    </p>
  </div>
  <div mat-dialog-actions>
    <div *ngIf="data.type == 1" class="w-full flex gap-4 justify-end pr-4">
      <button class="delete-btn" (click)="yes()">Yes</button>
      <button class="edit-btn" (click)="no()">No</button>
    </div>
  </div>
  }
</div>
