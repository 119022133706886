<h1 class="page-heading">Signup</h1>
<section class="container flex justify-center">
  <form [formGroup]="signupForm" class="flex flex-col w-6/12">
    <div class="form-field">
      <mat-label>Name<span class="required">*</span></mat-label>
      <mat-form-field>
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>
    <div class="form-field">
      <mat-label>Email<span class="required">*</span></mat-label>
      <mat-form-field>
        <input matInput formControlName="email" />
      </mat-form-field>
    </div>
    <div class="form-field">
      <mat-label>Password<span class="required">*</span></mat-label>
      <mat-form-field>
        <input matInput formControlName="password" />
        <mat-hint
          class="text-red-500"
          *ngIf="
            signupForm.get('password')!.invalid &&
            signupForm.get('password')!.dirty
          "
          >Minimum eight characters, at least one letter, one number and one
          special character.
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="flex justify-center mt-3">
      <button
        mat-raised-button
        class="main-btn w-32"
        [disabled]="signupForm.invalid"
        (click)="signup()"
      >
        Save
      </button>
    </div>
  </form>
</section>
