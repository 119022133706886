import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loading-placeholder',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading-placeholder.component.html',
  styles: `
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid grey;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } `,
})
export class LoadingPlaceholderComponent {}
