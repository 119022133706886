import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-header',
  standalone:true,
  imports:[CommonModule,RouterLink,RouterLinkActive],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  version="1.0.8";
  links = [
    {
      text: 'Extension Static Pages',
      route: '/extension-statics',
    },
    {
      text: 'Extension Landing Pages',
      route: '/extension-landings',
    },
    {
      text: 'Search Domain Pages',
      route: 'search-domains',
    },
    // {
    //   text: 'Installer Static Pages',
    //   route: 'installer-statics',
    // },
  ];
  constructor(public authService: AuthService,private router:Router) {

  }
  logout(){
    this.authService.logout();
    this.router.navigateByUrl("/login");
  }
}
