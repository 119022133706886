import { Component, inject } from '@angular/core';
import { FormBuilder, FormsModule, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { MaterailModule } from '../../materail/materail.module';
import { LoaderService } from '../../services/loader.service';
import { NotificationService } from '../../services/notification.service';
import { CacheDataService } from '../../services/cache-data.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [MaterailModule, FormsModule, ReactiveFormsModule, ToastrModule],
  standalone: true,
})
export class LoginComponent {
  token: string = '';
  loader = inject(LoaderService);
  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(private authService: AuthService, private router: Router, private fb: FormBuilder, private notificationService: NotificationService) {
    if (this.authService.loggedIn) {
      this.router.navigateByUrl('/extension-statics');
    }
  }

  login() {
    const { email, password } = this.loginForm.value;
    this.loader.show();
    this.authService.login(email!, password!).subscribe({
      next: async () => {
        try {
          await this.cacheDataService.init();
        } catch (e) {
          console.warn('login', e);
        }
        this.notificationService.success('Login successfully');
        this.router.navigateByUrl('/extension-statics');
      },
      complete: () => {
        this.loader.hide();
      },
    });
  }

  cacheDataService = inject(CacheDataService);
}
