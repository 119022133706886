import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment.development';
import { CreatedResponse, ICompany, IDistributor, IPageType, ITemplate, ITopic, ListResultResponse, LoginResponse, PublishResponse } from '../types/auth-response';
import { IStaticPage } from '../types/static-page';
import { BrowserType } from '../constants/enums';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/internal/operators/map';
import { ILandingPage } from '../types/landing-page';
import { ISearchDomainPage } from '../types/search-domain-page';
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  brosersList!: BrowserType[];
  topicList!: ITopic[];
  constructor(private http: HttpClient) {}

  login(email: string, password: string) {
    return this.http.post<LoginResponse>(environment.apiUrl + '/api/Auth/login', {
      email,
      password,
    });
  }
  signup(name: string, email: string, password: string) {
    return this.http.post<LoginResponse>(environment.apiUrl + '/api/Auth/signup', {
      name,
      email,
      password,
    });
  }
  getNewToken() {
    return this.http.get(`${environment.apiUrl}/api/auth/newtoken`);
  }
  getPageTypeByTopic(topicId: number) {
    return this.http.get<ListResultResponse<IPageType>>(`${environment.apiUrl}/api/PageType/getpagetypebytopic?topicid=${topicId}`);
  }
  getTemplateByPagetype(pageTypeId?: number) {
    return this.http.get<ListResultResponse<ITemplate>>(`${environment.apiUrl}/api/Template/gettemplatebypagetype?pagetypeid=${pageTypeId || ''}`);
  }
  getStaticPage(staticPageId: number) {
    return this.http.get<ListResultResponse<IStaticPage>>(`${environment.apiUrl}/api/ExtensionStaticPage/getextensionstaticpage?staticpageid=${staticPageId}`);
  }
  getStaticPages() {
    return this.http.get<ListResultResponse<IStaticPage>>(`${environment.apiUrl}/api/ExtensionStaticPage/getextensionstaticpage?`);
  }
  addStaticPage(staticPage: any) {
    return this.http.post<CreatedResponse>(`${environment.apiUrl}/api/ExtensionStaticPage/addextensionstaticpage`, staticPage);
  }
  updateStaticPage(staticPageId: number, staticPage: any) {
    return this.http.put<CreatedResponse>(`${environment.apiUrl}/api/ExtensionStaticPage/extensionstaticpage/${staticPageId}`, staticPage);
  }
  publishStaticPage(templateId: number, extensionStaticPageId: number) {
    return this.http.post<PublishResponse>(`${environment.apiUrl}/api/ExtensionStaticPage/publish`, { templateId, extensionStaticPageId });
  }

  uploadImage(image: string) {
    return this.http.post<{ url: string }>(`${environment.apiUrl}/api/Image/uploadImage`, { image });
  }

  //landing pages
  getExtensionLandingPages() {
    return this.http.get<ListResultResponse<ILandingPage>>(`${environment.apiUrl}/api/ExtensionLandingPage/getextensionlandingpage?`);
  }
  getExtensionLandingPage(landingPageId: number) {
    return this.http
      .get<ListResultResponse<ILandingPage>>(`${environment.apiUrl}/api/ExtensionLandingPage/getextensionlandingpage?landingPageId=${landingPageId}`)
      .pipe(map((x) => x?.data[0]));
  }

  addLandingPage(page: ILandingPage) {
    return this.http.post<CreatedResponse>(`${environment.apiUrl}/api/ExtensionLandingPage/addextensionlandingpage`, page);
  }
  updateLandingPagee(landingpageid: number, page: ILandingPage) {
    return this.http.put<CreatedResponse>(`${environment.apiUrl}/api/ExtensionLandingPage/extensionlandingpage/${landingpageid}`, page);
  }
  publishLandingPage(templateId: number, extensionLandingpageId: number) {
    return this.http.post<PublishResponse>(`${environment.apiUrl}/api/ExtensionLandingPage/publish`, { templateId, extensionLandingpageId });
  }

  // search domain

  getSearchDomainPage(staticPageId: number) {
    return this.http.get<ListResultResponse<ISearchDomainPage>>(`${environment.apiUrl}/api/SearchDomainStaticPage/getsearchdomainstaticpage?staticpageid=${staticPageId}`).pipe(map(m=>m.data[0]));
  }
  getSearchDomainPages() {
    return this.http.get<ListResultResponse<ISearchDomainPage>>(`${environment.apiUrl}/api/SearchDomainStaticPage/getsearchdomainstaticpage?`);
  }
  addSearchDomainPage(staticPage: any) {
    return this.http.post<CreatedResponse>(`${environment.apiUrl}/api/SearchDomainStaticPage/addsearchdomainstaticpage`, staticPage);
  }
  updateSearchDomainPage(staticPageId: number, staticPage: any) {
    return this.http.put<CreatedResponse>(`${environment.apiUrl}/api/SearchDomainStaticPage/searchdomainstaticpage/${staticPageId}`, staticPage);
  }
  publishSearchDomainPage(templateId: number, searchDomainStaticPageId: number) {
    return this.http.post<PublishResponse>(`${environment.apiUrl}/api/SearchDomainStaticPage/publish`, { templateId, searchDomainStaticPageId });
  }

}
