import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  compareNumber(a: number, b: number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareString(a: string, b: string, isAsc: boolean) {
    if (!a) {
      a = '';
    }
    if (!b) {
      b = '';
    }
    a=a+"";
    b=b+"";
    return (a?.toLowerCase() < b?.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a: Date, b: Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortAnArray(arr: Array<any>) {
    return arr.sort((a: any, b: any) => {
      return this.compareGeneral(a, b, true);
    });
  }
  compareGeneral(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
