<div class="form-field relative">
    <mat-label>{{label}} <span class="required">*</span></mat-label>
    <mat-form-field>
      <input
        type="text"
        matInput
        [readonly]="true"
        [ngStyle]="{ 'background-image': imageUrl ? 'url(' + imageUrl + ')' : null }"
        style="background-size: contain; background-repeat: no-repeat"
      />
    </mat-form-field>
    <div class="browse-file-container">
      @if(!disabled){
        <input type="file" class="file-input-button" (change)="onImageUpload($event)" />
      }@else{
        <button type="button" class="file-input-button" ></button>
      }
    </div>
  </div>