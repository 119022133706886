import { Component, Inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MaterailModule } from '../../../materail/materail.module';
import {ITemplatePreviewData} from '../../../types/templatePreviewData'
import { LoadingPlaceholderComponent } from '../loading-placeholder/loading-placeholder.component';

@Component({
  selector: 'app-template-preview',
  standalone: true,
  imports: [CommonModule, MaterailModule, LoadingPlaceholderComponent,NgOptimizedImage],
  templateUrl: './template-preview.component.html',
  styles: `
  .template-preview{
    background:red;
  }
  `
})

export class TemplatePreviewComponent{    
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITemplatePreviewData
  ) {}  
}