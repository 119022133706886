import { Injectable, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmBoxComponent, DialogBoxType } from '../components/shared/confirm-box/confirm-box.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toaster: ToastrService) {}
  dialog = inject(MatDialog);
  success(message: string, title: string = '') {
    this.toaster.success(message, title);
  }
  error(message: string, title: string = '') {
    this.toaster.error(message, title);
  }
  info(message: string, title: string = '') {
    this.toaster.info(message, title);
  }

  showErros(errors: string[]) {
    const ref = this.dialog.open(ConfirmBoxComponent, {
      backdropClass: 'confirmation-box-bg',
      disableClose: true,
      width: '50vw',
      minWidth:'400px',
      data: {
        errors: errors,
        type: DialogBoxType.error,
      },
    });
  }
}
