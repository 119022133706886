import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { HttpService } from './services/http.service';
import { CacheDataService } from './services/cache-data.service';
import { AuthService } from './services/auth.service';
import { LoadingPlaceholderComponent } from './components/shared/loading-placeholder/loading-placeholder.component';
import { NotificationService } from './services/notification.service';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NgxSpinnerModule, ToastrModule, LoadingPlaceholderComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'StaticPagesUI';
  cacheDataService = inject(CacheDataService);
  authService = inject(AuthService);
  router = inject(Router);
  notification = inject(NotificationService);
  loader = inject(LoaderService);
  couldNotGetData=false;
  async ngOnInit() {
    if (this.authService.loggedIn) {
      // it will prefetch and cache
      try {
        await this.cacheDataService.init();
      } catch (e) {
        console.warn("app",e);
        this.couldNotGetData=true;
      }
    }
    setInterval(() => {
      if (this.authService.loggedIn && this.authService.isTokenExpired()) {
        console.log("logout from app interval")
        this.authService.logout();
        if (!window.location.href.includes('/login')) {
          this.router.navigate(['login']);
          this.notification.info(`Please login!`);
          this.loader.hide();
        }
      }
    }, 6000);
  }
}
